import React from "react";
import { Flex, Text, TextKind } from "@appsmith/ads";
import {
  createMessage,
  CURRENCY_FORMAT,
  SEAT_BASED_BILLING_PRICE_PER_MONTH_TEXT,
} from "constants/Messages";

interface LineItemProps {
  amount: number;
  amountKind?: TextKind;
  title: string;
  titleKind?: TextKind;
  quantity?: number;
  subTitle?: string;
  subTitleKind?: TextKind;
  unitPrice?: number;
  noBorder?: boolean;
}

const LineItem = ({
  amount,
  amountKind = "heading-xs",
  noBorder = false,
  quantity,
  subTitle,
  subTitleKind = "body-m",
  title,
  titleKind = "heading-xs",
  unitPrice,
}: LineItemProps) => {
  return (
    <Flex
      alignItems="center"
      borderBottom={noBorder ? "none" : "1px solid var(--ads-v2-color-border)"}
      justifyContent="space-between"
      paddingBottom="spaces-5"
      paddingTop="spaces-3"
    >
      <Flex flexDirection="column" w="60%">
        <Text kind={titleKind}>{title}</Text>
        {subTitle && <Text kind={subTitleKind}>{subTitle}</Text>}
      </Flex>
      <Text className="w-[64px] text-center" kind="body-m">
        {quantity}
      </Text>
      <Text className="w-[64px] text-center" kind="body-m">
        {unitPrice
          ? createMessage(SEAT_BASED_BILLING_PRICE_PER_MONTH_TEXT, unitPrice)
          : ""}
      </Text>
      <Text className="w-[64px] text-center" kind={amountKind}>
        {createMessage(CURRENCY_FORMAT, amount)}
      </Text>
    </Flex>
  );
};

export { LineItem };
