import { takeLatest, put, all, call } from "redux-saga/effects";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { ApiResponse } from "types/ApiTypes";
import { toast } from "@appsmith/ads";
import log from "loglevel";
import { ACCOUNT_FETCH_ERROR, createMessage } from "constants/Messages";
import { isValidResponse } from "./utilitySaga";
import { AccountApiResponse } from "types/ApiResponseTypes";
import UserApi from "api/UserApi";
import { updateAccountDetails } from "actions/AccountActions";
import {
  ACCOUNT_STATUS_TYPES,
  ACTION_TYPES,
} from "constants/AccountTypeConstants";
import { fetchLicenseDetails, startTrial } from "actions/LicenseActions";
import { authenticateUserSuccess } from "actions/UserActions";
// import { LOCAL_STORAGE_KEYS } from "constants/AppConstants";

export function showErrorToaster() {
  toast.show(createMessage(ACCOUNT_FETCH_ERROR), {
    kind: "error",
  });
}

export function* fetchAccountDetails() {
  try {
    const response: ApiResponse<AccountApiResponse> = yield call(
      UserApi.account,
    );
    const isValid: boolean = yield call(isValidResponse, response);
    if (isValid) {
      yield put(updateAccountDetails(response.data));
      if (response?.data?.actionType === ACTION_TYPES.SIGNUP) {
        //SIGN UP flow
        yield put(startTrial());

        // local storage set done at customer/public/index.html
        // const signUpValue = localStorage.getItem(
        //   LOCAL_STORAGE_KEYS.SIGN_UP_VALUE,
        // );

        // if (!signUpValue) {
        //TODO: ^ Revert above condition and Uncomment below statements for CE as the default flow and BE Trial on query params
        // if (signUpValue === SIGN_UP_TYPE.BUSINESS_TRIAL) {
        // yield call(LicenseApi.startTrial);
        // yield put(startTrial());
        // }
        // } else {
        //   localStorage.removeItem(LOCAL_STORAGE_KEYS.SIGN_UP_VALUE);
        //   yield put(authenticateUserSuccess());
        // }
      } else {
        //LOGIN flow
        if (
          response?.data?.licenseAccountStatus !== ACCOUNT_STATUS_TYPES.FREE
        ) {
          yield put(fetchLicenseDetails());
        } else {
          yield put(authenticateUserSuccess());
        }
      }
    } else {
      throw new Error(createMessage(ACCOUNT_FETCH_ERROR));
    }
  } catch (error: any) {
    log.error(error);
    yield call(showErrorToaster);
  }
}

export function* AccountSaga() {
  yield all([
    takeLatest(ReduxActionTypes.FETCH_ACCOUNT_DETAILS, fetchAccountDetails),
  ]);
}
