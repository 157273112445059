import { RemainingTime } from "types/LicenseTypes";

export function createMessage(
  format: (...strArgs: any[]) => string,
  ...args: any[]
) {
  return format(...args);
}

export const INSTALL_TEXT = () => `Install`;
export const UPGRADE_TEXT = () => `Upgrade`;
export const LICENSE_PAGE_HEADING = () => `Setup`;
export const LICENSE_INSTALLATION_HEADING = () => `License and validity`;
export const INSTALLATION_GUIDE_HEADING = () => `Get started with Appsmith`;
export const BILLING_AND_USAGE_HEADING = () => `Billing and usage`;
export const MANAGE_BILLING = () => `Manage billing`;
export const MANAGE_YOUR_BILLING = () => `Manage your billing`;
export const STRIPE_LINK_TEXT = () => `Stripe dashboard`;
export const SETUP_TEXT = () => `Setup`;
export const PLANS_TEXT = () => `Plans`;
export const BILLING_TEXT = () => `Billing and usage`;
export const BILLING_CONSOLE_TEXT = () => `Billing console`;
export const YOUR_LICENSE_KEY_TEXT = () => `Your license key`;
export const TRIAL_TEXT = () => `Trial mode`;
export const ACTIVE_TEXT = () => `Active`;
export const SUBSCRIPTION_CANCELLED = () => `Subscription cancelled`;
export const VALID_UNTIL = () => `Valid until`;
export const HIDE_LICENSE = () => `Hide license`;
export const SHOW_LICENSE = () => `Show license`;
export const NOT_AVAILABLE = () => `Not available`;
export const PER_HOUR_OF_USAGE = () => `per hour of usage`;
export const NO_CHARGE_FOR_BUILDING_APPS = () =>
  `We don't charge for building apps, just how much you use them.`;
export const ALL_USERS_ARE_BILLED_AT = () =>
  `All users of your apps on Appsmith Business Edition are billed at 40 cents an
  hour, capped at $20 per user per month.`;
export const HOURS_PER_USER_PER_MONTH = () => `Hours per user per month`;
export const BILLING_PER_USER_PER_MONTH = () => `Billing per user per month`;
export const TO_TEXT = () => `to`;
export const PER_HOUR = () => `per hour`;
export const MORE_THAN = () => `More than`;
export const USAGE_TEXT = () => `Usage`;
export const CURRENT_CYCLE_TEXT = () => `Current cycle`;
export const HOURS_TEXT = () => `hours`;
export const COPY_TO_CLIPBOARD = () => `Copy to clipboard`;
export const YOUR_LICENSE_KEY_HAS_EXPIRED = () =>
  `Your license key has expired`;
export const PAYMENT_SUCCESSFULL_MESSAGE = () =>
  `Congratulations, your license key is being updated in the background`;
export const PAYMENT_SUCCESSFULL_RETRY = () => `Retry`;

export const USAGE_ERROR_MESSAGE_HEADER = () => "Error connecting to server";
export const USAGE_ERROR_MESSAGE_BODY = () =>
  "Usage information cannot be fetched at the moment!";
export const USAGE_ERROR_MESSAGE_RETRY = () => "Retry";
export const LICENSE_COPIED_TO_CLIPBOARD = () =>
  `License key copied to clipboard`;
export const OTHER_METHODS = () => `Other methods`;
export const USERS_TEXT = () => `Users`;
export const HOURS_USED = () => `Hours used`;
export const HOURS_BILLED = () => `Hours billed`;
export const PRICE_TEXT = () => `Price`;
export const HOUR_TEXT = () => `hour`;
export const PLAN_PRICE = () => `$0.4`;
export const YOUR_ACCOUNT_TEXT = () => `Account`;
export const OPEN_DOCS = () => `Open docs`;
export const OTHER_METHODS_HEADING = () =>
  `Choose a preferred method from the list`;

//Banner
export const TRIAL_ACTIVE = ({ count, suffix }: RemainingTime) =>
  `Your trial will expire in ${count} ${suffix}.`;
export const TRIAL_EXPIRED = () => `Your trial has expired.`;
export const TRIAL_EXPIRED_ENTERPRISE = () => `Your trial has expired.`;
export const PAID_EXPIRED = () =>
  `Your subscription has been cancelled and your license is no longer valid. Get a new license to continue using all the features.`;
export const PAID_EXPIRED_SELF_SERVE = () =>
  `Your subscription has been cancelled. Resubscribe to continue using all the features.`;
export const PAYMENT_FAILED_SELF_SERVE = () =>
  `Your most recent payment has failed.`;
export const PAYMENT_FAILED_SALES = () => `Your last payment is overdue.`;
export const PAYMENT_FAILED_ENTERPRISE = () => `Your payment is overdue. `;
export const PAYMENT_FAILED_ENTERPRISE_CONTACT = () =>
  `Complete your payment to avoid any interruptions or let us know if you are facing any issues.`;
export const UPGRADE = () =>
  `Subscribe to a paid plan to continue using all the features.`;
export const UPGRADE_ENTERPRISE = () =>
  `Subscribe to a paid plan to continue using all the features.`;
export const PAYMENT_FAILED_UPDATE_SELF_SERVE = ({
  count,
  suffix,
}: RemainingTime) =>
  `Update your payment methods or your instances will stop working in ${count} ${suffix}.`;
export const PAYMENT_FAILED_UPDATE_SALES = () =>
  `Please clear it soon to avoid any service disruptions.`;
export const GENERIC_ERROR_MESSAGE = () =>
  `Something went wrong, please try again.`;
export const ACCESS_TOKEN_ERROR = () => `Unable to get access token`;
export const LICESNE_FETCH_ERROR = () =>
  `Unable to fetch license, please try again.`;
export const ACCOUNT_FETCH_ERROR = () =>
  `Unable to fetch account details, please try again.`;
export const MEMBERS_FETCH_ERROR = () =>
  `Unable to fetch members, please try again.`;
export const UPCOMING_INVOICE_SUMMARY_FETCH_ERROR = () =>
  `Unable to fetch upcoming invoice summary, please try again.`;
export const SUBSCRIPTIONS_UPDATE_SEATS_ERROR = () =>
  `Unable to update seats, please try again.`;
export const SUBSCRIPTIONS_UPDATE_SEATS_SUCCESS = () =>
  `Seats updated successfully`;
export const FETCH_PRORATED_INVOICE_ERROR = () =>
  `Unable to fetch prorated invoice, please try again.`;

export const UPGRADE_CTA = () => `Upgrade`;
export const VISIT_STRIPE_PORTAL_CTA = () => `Visit Stripe portal`;
export const CONTACT_US_CTA = () => `Contact us`;
export const CURRENT_PLAN_CTA = () => `Current plan`;

//verify-email
export const VERIFY_EMAIL_HEADER = () => `Check your email`;
export const VERIFY_EMAIL_BODY = (email: string) =>
  `Please check your email (${email}) to verify your account.`;

//License Origins
export const ENTERPRISE = () => `Enterprise`;
export const SELF_SERVE = () => `Business`;
export const AIRGAPPED = () => `Airgapped`;

//Enterprise Billing

export const SEATS_PURCHASED = () => `Seats purchased`;
export const ACTIVE_USERS = () => `Active users`;
export const SALES_EMAIL = () => "sales@appsmith.com";
export const SUPPORT_EMAIL = () => "support@appsmith.com";
export const REACH_OUT = () => "Have any questions? Please reach out to ";
export const TOOLTIP_SEATS_PURCHASED = () =>
  "Number of user licenses purchased as part of the subscription.";
export const TOOLTIP_ACTIVE_USERS = () =>
  "Number of users who have used the platform for at least an hour during the current billing cycle.";
export const FREE_SESSIONS_TEXT = (totalHours: number, freeSessions: number) =>
  `Out of the total ${totalHours} hours available, ${freeSessions} hours have been carried forward from
the previous cycle.`;

//Self Serve Billing

export const SELF_SERVE_HOURS_USED_TOOLTIP = () =>
  "Total hours used by all users in the current billing cycle";
export const SELF_SERVE_ACTIVE_USERS_TOOLTIP = () =>
  "Number of users who have used platform for at least one hour in current billing cycle";
export const SELF_SERVE_HOURS_BILLED_TOOLTIP = () =>
  "Total hours for which customer is billed. For e.g., if a user has 100 hours of usage, they will get billed only for 50 hours";
export const SELF_SERVE_PROGRESS_LEGEND_PREPAID = (hours: number) =>
  `Prepaid (${hours} hrs)`;
export const SELF_SERVE_PROGRESS_LEGEND_OVERAGE = (hours: number) =>
  `Overage (${hours} hrs)`;
export const SELF_SERVE_PROGRESS_LEGEND_PREPAID_TOOLTIP = () =>
  "Total hours purchased upfront at start of billing cycle";
export const SELF_SERVE_PROGRESS_LEGEND_OVERAGE_TOOLTIP = () =>
  "Number of hours billed over and above the prepaid hours";

//Sidebar Banner
export const SIDEBAR_BANNER_HEADING = () => `Business edition trial`;
export const SIDEBAR_BANNER_DESCRIPTION = () =>
  `For large teams working at scale. Start a 15 day trial and experience business edition features`;
export const START_TRIAL_CTA = () => `Start trial`;

export const VISIT_DOCS_LINK_TEXT = () => `Visit docs`;
export const VISIT_DOCS_TEXT = () => `For more details visit our documentation`;
export const SIGN_OUT_TEXT = () => `Sign out`;

export const ENTERPRISE_CONTACT_MESSAGE_INTERCOM = () =>
  `Hello team, we are interested in the enterprise plan of Appsmith. Could you connect us to someone from the sales team?`;

export const BUSINESS_ENTERPRISE_PAID_EXPIRED_INTERCOM = () =>
  `Hello team, our paid license key has expired, and as a result, all our instances have become inactive. Could you please help us reactivate the license key at the earliest?`;

export const ENTERPRISE_TRIAL_ACTIVE_INTERCOM = () =>
  `Hello team, we are currently on an enterprise trial and would like to upgrade to the paid edition. Could you connect us to someone from the sales team?`;

export const ENTERPRISE_TRIAL_EXPIRED_INTERCOM = () =>
  `Hello team, our enterprise trial has expired and we would like to upgrade to the paid edition. Could you connect us to someone from the sales team?`;

export const ENTERPRISE_IN_GRACE_PERIOD_INTERCOM = () =>
  `Hello team, we are on an enterprise plan and are unable to complete the payment. Could you please help us resolve this issue?`;

export const PRICING_SUMMARY_PURCHASE_SEATS = {
  back_text: () => `Go back`,
  heading: () => `Business plan`,
  description: (minSeats: number) =>
    `Each user needs a seat to access Appsmith. Minimum of ${minSeats} seats are required to purchase business plan.`,
  select_seats: () => `Total seats`,
  members_heading: (seats: number) => `Active users (${seats})`,
  members_description: () =>
    `These users can access Appsmith only if they've got a seat`,
  members_search_placeholder: () => `Search for users`,
  summary_heading: () => `Billing summary`,
  summary_heading_prorated: () => `Prorated charges overview`,
  summary_total: (timePeriod: string) => `Total due ${timePeriod}`,
  summary_pay_now: () => `Checkout`,
  summary_pay_now_add_remove_seats: () => `Confirm changes`,
  members_empty_state: () => `There are no users across any instances`,
  summary_invoice_period: (dateRange: string) =>
    `Invoice for the period of ${dateRange}`,
  min_seats_warning: (minSeats: number) =>
    `You need to buy minimum of ${minSeats} seats in order to purchase business plan.`,
  seats_full_warning: (maxSeats: number) =>
    `If you need more than ${maxSeats} seats, you need to opt for enterprise plan.`,
  occupied_subtext: (occupiedSeats: number, purchasedSeats: number) =>
    `${occupiedSeats}/${purchasedSeats} seats are occupied by users`,
  summary_no_seats: () => `0 seats added/removed`,
  summary_seats_removed: (seats: number) => `${seats} seats removed`,
  sumary_seat_removed_subtext: () =>
    `No credit would be given for the current month for seats removed`,
};

export const MEMBERS_TEXT = () => `Users`;
export const PLAN_TAG = (plan: string) => `${plan} plan`;
export const INVITE_MEMBERS_CTA = () => `Invite users`;
export const SEATS_ASSIGNMENT_TEXT = (assigned: number, total: number) =>
  `${assigned}/${total} users have seats`;
export const SEARCH_MEMBERS_PLACEHOLDER = () => `Search for users`;
export const ADD_REMOVE_SEATS_CTA = () => `Modify seats`;
export const TOTAL_CONSUMED_SEATS_TEXT = () => `Total consumed seats`;
export const SEATS_TEXT = (seats: number) => `${seats} seats`;
export const SEATS_AVAILABLE_TEXT = (seats: number) => `${seats} available`;
export const VALID_TILL_TEXT = (date: string) => `Valid till ${date}`;
export const ALL_SEATS_ASSIGNED_TOOLTIP = () =>
  "100% of the available seats are assigned, add more seats to assign users";
export const ASSIGN_SEATS_CTA = () => `Assign seats`;
export const UNASSIGN_SEATS_CTA = () => `Unassign seats`;
export const SELECTED_SEATS_TEXT = (selectedCount: number) =>
  `${selectedCount} selected`;
export const SEAT_ASSIGNED_TEXT = () => `Seat assigned`;
export const NO_SEAT_ASSIGNED_TEXT = () => `No seat assigned`;

export const INVITE_MEMBERS_MODAL_HEADER = () => `Invite users`;
export const INVITE_MEMBERS_MODAL_TEXTBOX_PLACEHOLDER = () =>
  `Enter email addresses`;
export const INVITE_MEMBERS_MODAL_BUTTON_TEXT = () => `Invite users`;
export const INVITE_MEMBERS_SUCCESS_MESSAGE = () =>
  `Users invited successfully`;
export const INVITE_MEMBERS_FAILED_MESSAGE = () => `Failed to invite users`;

export const UPDATE_MEMBERS_SUCCESS_MESSAGE = () =>
  `Users updated successfully`;
export const UPDATE_MEMBERS_FAILED_MESSAGE = () => `Failed to update users`;

export const LOADING_MEMBERS_MESSAGE = () => `Loading users...`;

export const USER_TABLE = {
  user: () => `User`,
  access: () => `Access`,
  instance: () => `Instance`,
  status: () => `Status`,
};

// Seat Based billing
export const BILLING_SEAT_BASED_TEXT = () => `Billing`;
export const SEAT_BASED_BILLING_PLAN_TEXT = () => `Plan: Business`;
export const SEAT_BASED_BILLING_UPGRADE_CTA = () => `Upgrade`;
export const SEAT_BASED_BILLING_PRICE_PER_MONTH_TEXT = (price: number) =>
  `${createMessage(CURRENCY_FORMAT, price)} seat/month`;
export const SEAT_BASED_BILLING_TOTAL_SEATS_TEXT = (totalSeats: number) =>
  `${totalSeats} seats`;
export const SEAT_BASED_BILLING_RENEWAL_DATE_TEXT = (endDate: string) =>
  `Renews on ${endDate}`;
export const SEAT_BASED_PRICING_ENV_DATA_FETCH_ERROR = () =>
  `Unable to fetch seat based pricing env data, please try again.`;

export const SEAT_BASED_BILLING_MANAGE_YOUR_BILLING_TEXT = () =>
  `To view invoices or manage your subscription`;

export const INVOICE_CARD_TITLE = () => `Upcoming invoice`;
export const INVOICE_CARD_DESCRIPTION = (date: string) => `On ${date}`;
export const INVOICE_CARD_INVOICE_ITEMS = {
  description: () => `Description`,
  quantity: () => `Quantity`,
  unit_price: () => `Unit price`,
  amount: () => `Amount`,
  subtotal: () => `Subtotal`,
  total: (date: string) => `Total amount due (next charge on ${date})`,
};

export const CURRENCY_FORMAT = (price: number) =>
  price < 0 ? `-$${Math.abs(price)}` : `$${price}`;

export const BUSINESS_PLAN_DESCRIPTION = (price: number) =>
  `${createMessage(CURRENCY_FORMAT, price)} seat/month.`;
