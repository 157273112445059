import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initiateCheckout } from "actions/paymentActions";
import { getFlowType } from "selectors/LicenseSelectors";
import { FlowType } from "types/LicenseTypes";
import { PRICING_SUMMARY } from "constants/RouteContants";

const useHandleUpgradeClick = () => {
  const dispatch = useDispatch();
  const flowType = useSelector(getFlowType);

  const handleUpgradeClick = useCallback(() => {
    if (flowType === FlowType.USAGE_BASED) {
      dispatch(initiateCheckout());
    } else {
      window.location.href = PRICING_SUMMARY;
    }
  }, [dispatch, flowType]);

  return { handleUpgradeClick };
};

export { useHandleUpgradeClick };
