import { AxiosPromise } from "axios";
import { ApiResponse } from "types/ApiTypes";
import { LicenseResponseType } from "types/LicenseTypes";
import { getISOFormatDateString } from "utils/ApiUtils";
import API from "./Api";
import { USAGE_STATS } from "./constants";

class UsageApi {
  static async get(
    stripeCustomerId: string,
    date = new Date(),
  ): AxiosPromise<ApiResponse<LicenseResponseType>> {
    return API.get(`${USAGE_STATS}/${stripeCustomerId}`, {
      date: getISOFormatDateString(date),
    });
  }
}

export default UsageApi;
