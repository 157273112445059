import { Button } from "@appsmith/ads";
import React, { useRef, useState } from "react";
import { StyledCommand, Container } from "./CommandBox.styles";

function CommandBox({ children }: { children: React.ReactNode }) {
  const commandRef = useRef<HTMLDivElement>(null);
  const [copyIcon, setCopyIcon] = useState("duplicate");

  function copyCode() {
    const code = commandRef.current?.innerText;
    const textarea = document.createElement("textarea");
    textarea.value = code || "";
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopyIcon("check-line");
    setTimeout(() => {
      setCopyIcon("duplicate");
    }, 1000);
  }

  return (
    <StyledCommand>
      <div ref={commandRef}>{children}</div>
      <Container className="copy-command" onClick={copyCode}>
        <Button
          data-testid="license-copy"
          isIconButton
          kind="tertiary"
          size="md"
          startIcon={copyIcon}
        />
      </Container>
    </StyledCommand>
  );
}

export default CommandBox;
