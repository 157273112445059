import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { createReducer } from "utils/ReducerUtils";
import { FlowType, LicenseResponseType } from "types/LicenseTypes";
import { ReduxAction } from "types/ReduxTypes";

export type LicenseStateType = {
  details: LicenseResponseType | null;
  isLoading: boolean;
  startTrialLoader: boolean;
  error: string | null;
  flowType: FlowType;
};

const initialState: LicenseStateType = {
  details: null,
  isLoading: false,
  startTrialLoader: false,
  error: null,
  flowType: FlowType.SEAT_BASED,
};

export const licenseReducer = createReducer(initialState, {
  [ReduxActionTypes.FETCH_LICENSE]: (state: LicenseStateType) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [ReduxActionTypes.FETCH_LICENSE_SUCCESS]: (
    state: LicenseStateType,
    action: ReduxAction<LicenseResponseType>,
  ) => {
    return {
      ...state,
      isLoading: false,
      startTrialLoader: false,
      details: action.payload,
    };
  },
  [ReduxActionTypes.FETCH_LICENSE_FAILED]: (state: LicenseStateType) => {
    return {
      ...state,
      isLoading: false,
      startTrialLoader: false,
    };
  },
  [ReduxActionTypes.START_TRIAL]: (state: LicenseStateType) => {
    return {
      ...state,
      startTrialLoader: true,
    };
  },
  [ReduxActionTypes.START_TRIAL_FAILED]: (state: LicenseStateType) => {
    return {
      ...state,
      startTrialLoader: false,
    };
  },
  [ReduxActionTypes.UPDATE_FLOW_TYPE]: (
    state: LicenseStateType,
    action: ReduxAction<FlowType>,
  ) => {
    return {
      ...state,
      flowType: action.payload,
    };
  },
});
