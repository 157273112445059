import {
  FetchMembersPayload,
  GetMembersCountPayload,
  MembersResponseType,
  UpdateMember,
} from "types/MembersTypes";
import API from "./Api";
import { AxiosPromise } from "axios";
import { ApiResponse } from "types/ApiTypes";
import { INVITE_MEMBERS, MEMBERS, MEMBERS_COUNT } from "./constants";

export default class {
  static fetchMembers(
    payload?: FetchMembersPayload,
  ): AxiosPromise<ApiResponse<MembersResponseType>> {
    const { email = "", page = 0, size = 15 } = payload || {};

    return API.get(MEMBERS, {
      page,
      size,
      email,
    });
  }

  static updateMembers(
    members: UpdateMember[],
  ): AxiosPromise<ApiResponse<MembersResponseType>> {
    return API.put(MEMBERS, {
      members,
    });
  }

  static inviteMembers(
    emails: string[],
  ): AxiosPromise<ApiResponse<MembersResponseType>> {
    return API.post(INVITE_MEMBERS, {
      addedMembers: emails,
    });
  }

  static getMembersCount(): AxiosPromise<ApiResponse<GetMembersCountPayload>> {
    return API.get(MEMBERS_COUNT);
  }
}
