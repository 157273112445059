import {
  createMessage,
  SETUP_TEXT,
  PLANS_TEXT,
  BILLING_TEXT,
  MEMBERS_TEXT,
  BILLING_SEAT_BASED_TEXT,
} from "constants/Messages";
import { useSelector } from "react-redux";
import {
  getFlowType,
  shouldShowBillingUsage,
  shouldShowMembers,
  shouldShowPlans,
} from "selectors/LicenseSelectors";
import analyticEvents from "constants/AnalyticEvents";
import { FlowType } from "types/LicenseTypes";

export default function useSidebarItems() {
  const showBillingUsagePage = useSelector(shouldShowBillingUsage);
  const showPlansPage = useSelector(shouldShowPlans);
  const showMembersPage = useSelector(shouldShowMembers);
  const flowType = useSelector(getFlowType);

  const sidebarMenuItems = [
    {
      id: "license",
      text: createMessage(SETUP_TEXT),
      path: "/license",
      isVisible: true,
      icon: "key-2-line",
      segmentEvent: analyticEvents.LICENSE_PAGE_CLICK_PORTAL,
    },
    {
      id: "plans",
      text: createMessage(PLANS_TEXT),
      path: "/plans",
      isVisible: showPlansPage,
      icon: "bill-line",
      segmentEvent: analyticEvents.PLAN_BUILDER_CLICK_PORTAL,
    },
    {
      id: "billing",
      text:
        flowType === FlowType.SEAT_BASED_UPDATE ||
        flowType === FlowType.SEAT_BASED
          ? createMessage(BILLING_SEAT_BASED_TEXT)
          : createMessage(BILLING_TEXT),
      path: "/billing",
      isVisible: showBillingUsagePage,
      icon: "file-paper-2-line",
      segmentEvent: analyticEvents.USAGE_PAGE_CLICK_PORTAL,
    },
    {
      id: "members",
      text: createMessage(MEMBERS_TEXT),
      path: "/members",
      isVisible: showMembersPage,
      icon: "user-3-line",
      segmentEvent: analyticEvents.MEMBERS_PAGE_CLICK_PORTAL,
    },
  ];
  return sidebarMenuItems;
}
