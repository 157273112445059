export enum FEATURE_SECTION_NAME {
  BUILD = "Build",
  DEPLOY_MANAGE = "Deploy and manage",
  ADMIN_SECURE = "Administer and secure",
  SUPPORT = "Support",
}

export enum FEATURE_NAME {
  // Build
  USER = "Users",
  APPS = "Apps",
  WORKSPACES = "Workspaces",
  WORKFLOWS = "Workflows",
  REUSABLE_PACKAGES = "Reusable packages",
  CUSTOM_CODE = "Custom code",
  CUSTOM_WIDGETS = "Custom widgets",
  CUSTOM_INTEGRATIONS = "Custom integrations",
  APPSMITH_BRANDING = "Appsmith branding",
  CUSTOM_BRANDING = "Custom branding",
  EMBEDDING = "Embedding",
  // Deploy and manage
  SELF_HOSTING = "Self-hosting",
  CLOUD_HOSTING = "Cloud hosting",
  MANAGED_HOSTING = "Managed hosting",
  CUSTOM_DOMAINS = "Custom domains",
  VERSIONING_GIT = "Versioning with Git",
  CI_CD = "CI/CD",
  GIT_BRANCH_PROTECTION = "Git branch protection and default branch configuration",
  CUSTOM_ENVIRONMENTS = "Custom environments",
  BACKUP_RESTORE = "Backup and restore",
  AIRGAPPED = "Airgapped edition",
  HIGH_AVAILABILITY = "High availability",
  // Administer and secure
  SSO_IDP = "SSO & IDP",
  AUDIT_LOGS = "Audit logs",
  ACCESS_CONTROLS = "Access controls",
  SCIM = "User provision through SCIM",
  SOC_2 = "SOC 2 type 2 compliance",
  // Support
  TECH_SUPPORT = "Technical support",
  APP_DEVELOPMENT = "App development support",
  APP_MIGRATION = "App migration support",
}

export enum PLAN_NAME {
  COMMUNITY = "Community",
  BUSINESS = "Business",
  ENTERPRISE = "Enterprise",
}

export enum PLAN_TYPES {
  BUSINESS = "business",
  ENTERPRISE = "enterprise",
  COMMUNITY = "community",
  BUSINESS_SEAT_BASED = "business_seat_based",
}

export const ALL_PLANS = [
  {
    name: PLAN_NAME.COMMUNITY,
    type: PLAN_TYPES.COMMUNITY,
    iconName: "community-plan.svg",
    details: ["$0"],
    features: {
      [FEATURE_SECTION_NAME.BUILD]: [
        { [FEATURE_NAME.USER]: "Up to 5 (for cloud)" },
        { [FEATURE_NAME.APPS]: "Unlimited" },
        { [FEATURE_NAME.WORKSPACES]: "5" },
        { [FEATURE_NAME.WORKFLOWS]: false },
        { [FEATURE_NAME.REUSABLE_PACKAGES]: false },
        { [FEATURE_NAME.CUSTOM_CODE]: true },
        { [FEATURE_NAME.CUSTOM_WIDGETS]: true },
        { [FEATURE_NAME.CUSTOM_INTEGRATIONS]: false },
        { [FEATURE_NAME.APPSMITH_BRANDING]: false },
        { [FEATURE_NAME.CUSTOM_BRANDING]: false },
        { [FEATURE_NAME.EMBEDDING]: "Embed public apps" },
      ],
      [FEATURE_SECTION_NAME.DEPLOY_MANAGE]: [
        { [FEATURE_NAME.SELF_HOSTING]: true },
        { [FEATURE_NAME.CLOUD_HOSTING]: true },
        { [FEATURE_NAME.MANAGED_HOSTING]: false },
        { [FEATURE_NAME.CUSTOM_DOMAINS]: true },
        { [FEATURE_NAME.VERSIONING_GIT]: "3 repos" },
        { [FEATURE_NAME.CI_CD]: false },
        { [FEATURE_NAME.GIT_BRANCH_PROTECTION]: false },
        { [FEATURE_NAME.CUSTOM_ENVIRONMENTS]: false },
        {
          [FEATURE_NAME.BACKUP_RESTORE]:
            "When updating to a higher Appsmith version",
        },
        { [FEATURE_NAME.AIRGAPPED]: false },
        { [FEATURE_NAME.HIGH_AVAILABILITY]: false },
      ],
      [FEATURE_SECTION_NAME.ADMIN_SECURE]: [
        { [FEATURE_NAME.SSO_IDP]: "Google and GitHub SSO" },
        { [FEATURE_NAME.AUDIT_LOGS]: false },
        { [FEATURE_NAME.ACCESS_CONTROLS]: "3 standard pre-defined roles" },
        { [FEATURE_NAME.SCIM]: false },
        { [FEATURE_NAME.SOC_2]: false },
      ],
      [FEATURE_SECTION_NAME.SUPPORT]: [
        { [FEATURE_NAME.TECH_SUPPORT]: "Community support on Discord" },
        { [FEATURE_NAME.APP_DEVELOPMENT]: false },
        { [FEATURE_NAME.APP_MIGRATION]: false },
      ],
    },
  },
  {
    name: PLAN_NAME.BUSINESS,
    type: PLAN_TYPES.BUSINESS,
    iconName: "business-plan.svg",
    details: [
      "<span>$40/month</span> for 100 hours of usage.",
      "Additional usage at <span>$0.40</span> per hour",
      "capped at <span>$20</span> per user per month",
    ],
    features: {
      [FEATURE_SECTION_NAME.BUILD]: [
        { [FEATURE_NAME.USER]: "Upto 100 users" },
        { [FEATURE_NAME.APPS]: "Unlimited" },
        { [FEATURE_NAME.WORKSPACES]: "Unlimited" },
        { [FEATURE_NAME.WORKFLOWS]: "10,000 runs" },
        { [FEATURE_NAME.REUSABLE_PACKAGES]: true },
        { [FEATURE_NAME.CUSTOM_CODE]: true },
        { [FEATURE_NAME.CUSTOM_WIDGETS]: true },
        { [FEATURE_NAME.CUSTOM_INTEGRATIONS]: false },
        { [FEATURE_NAME.APPSMITH_BRANDING]: "Removable" },
        { [FEATURE_NAME.CUSTOM_BRANDING]: false },
        { [FEATURE_NAME.EMBEDDING]: "Embed public apps" },
      ],
      [FEATURE_SECTION_NAME.DEPLOY_MANAGE]: [
        { [FEATURE_NAME.SELF_HOSTING]: true },
        { [FEATURE_NAME.CLOUD_HOSTING]: "Coming soon" },
        { [FEATURE_NAME.MANAGED_HOSTING]: false },
        { [FEATURE_NAME.CUSTOM_DOMAINS]: true },
        { [FEATURE_NAME.VERSIONING_GIT]: "Unlimited repos" },
        { [FEATURE_NAME.CI_CD]: false },
        { [FEATURE_NAME.GIT_BRANCH_PROTECTION]: false },
        { [FEATURE_NAME.CUSTOM_ENVIRONMENTS]: true },
        { [FEATURE_NAME.BACKUP_RESTORE]: "Anytime you want, synced to AWS S3" },
        { [FEATURE_NAME.AIRGAPPED]: false },
        { [FEATURE_NAME.HIGH_AVAILABILITY]: true },
      ],
      [FEATURE_SECTION_NAME.ADMIN_SECURE]: [
        { [FEATURE_NAME.SSO_IDP]: "Google and GitHub SSO" },
        { [FEATURE_NAME.AUDIT_LOGS]: true },
        { [FEATURE_NAME.ACCESS_CONTROLS]: "3 standard pre-defined roles" },
        { [FEATURE_NAME.SCIM]: false },
        { [FEATURE_NAME.SOC_2]: false },
      ],
      [FEATURE_SECTION_NAME.SUPPORT]: [
        { [FEATURE_NAME.TECH_SUPPORT]: "Priority e-mail and chat support " },
        { [FEATURE_NAME.APP_DEVELOPMENT]: false },
        { [FEATURE_NAME.APP_MIGRATION]: false },
      ],
    },
  },
  {
    name: PLAN_NAME.ENTERPRISE,
    type: PLAN_TYPES.ENTERPRISE,
    iconName: "enterprise-plan.svg",
    details: ["$2,500 / month for 100 users"],
    features: {
      [FEATURE_SECTION_NAME.BUILD]: [
        { [FEATURE_NAME.USER]: "Unlimited(starting from 100)" },
        { [FEATURE_NAME.APPS]: "Unlimited" },
        { [FEATURE_NAME.WORKSPACES]: "Unlimited" },
        { [FEATURE_NAME.WORKFLOWS]: "Unlimited runs" },
        { [FEATURE_NAME.REUSABLE_PACKAGES]: true },
        { [FEATURE_NAME.CUSTOM_CODE]: true },
        { [FEATURE_NAME.CUSTOM_WIDGETS]: true },
        { [FEATURE_NAME.CUSTOM_INTEGRATIONS]: true },
        { [FEATURE_NAME.APPSMITH_BRANDING]: "Removable" },
        { [FEATURE_NAME.CUSTOM_BRANDING]: true },
        { [FEATURE_NAME.EMBEDDING]: "Embed private apps" },
      ],
      [FEATURE_SECTION_NAME.DEPLOY_MANAGE]: [
        { [FEATURE_NAME.SELF_HOSTING]: true },
        { [FEATURE_NAME.CLOUD_HOSTING]: "" },
        {
          [FEATURE_NAME.MANAGED_HOSTING]:
            "Managed hosting on Appsmith Cloud(AWS and Azure)",
        },
        { [FEATURE_NAME.CUSTOM_DOMAINS]: true },
        { [FEATURE_NAME.VERSIONING_GIT]: "Unlimited repos" },
        { [FEATURE_NAME.CI_CD]: true },
        { [FEATURE_NAME.GIT_BRANCH_PROTECTION]: true },
        { [FEATURE_NAME.CUSTOM_ENVIRONMENTS]: true },
        { [FEATURE_NAME.BACKUP_RESTORE]: "Anytime you want, synced to AWS S3" },
        { [FEATURE_NAME.AIRGAPPED]: true },
        { [FEATURE_NAME.HIGH_AVAILABILITY]: true },
      ],
      [FEATURE_SECTION_NAME.ADMIN_SECURE]: [
        {
          [FEATURE_NAME.SSO_IDP]:
            "Okta, Auth0, Active Directory and any SAML, OIDC, OAuth",
        },
        { [FEATURE_NAME.AUDIT_LOGS]: true },
        {
          [FEATURE_NAME.ACCESS_CONTROLS]:
            "Granular, customizable permissions by roles and attributes",
        },
        { [FEATURE_NAME.SCIM]: true },
        { [FEATURE_NAME.SOC_2]: true },
      ],
      [FEATURE_SECTION_NAME.SUPPORT]: [
        {
          [FEATURE_NAME.TECH_SUPPORT]:
            "24x7 support coverage with response time SLAs",
        },
        { [FEATURE_NAME.APP_DEVELOPMENT]: "Dedicated solutions engineer" },
        { [FEATURE_NAME.APP_MIGRATION]: true },
      ],
    },
  },
];

export const PLANS_TABLE_STRUCTURE = [
  {
    name: FEATURE_SECTION_NAME.BUILD,
    features: [
      FEATURE_NAME.USER,
      FEATURE_NAME.APPS,
      FEATURE_NAME.WORKSPACES,
      FEATURE_NAME.WORKFLOWS,
      FEATURE_NAME.REUSABLE_PACKAGES,
      FEATURE_NAME.CUSTOM_CODE,
      FEATURE_NAME.CUSTOM_WIDGETS,
      FEATURE_NAME.CUSTOM_INTEGRATIONS,
      FEATURE_NAME.APPSMITH_BRANDING,
      FEATURE_NAME.CUSTOM_BRANDING,
      FEATURE_NAME.EMBEDDING,
    ],
  },
  {
    name: FEATURE_SECTION_NAME.DEPLOY_MANAGE,
    features: [
      FEATURE_NAME.SELF_HOSTING,
      FEATURE_NAME.CLOUD_HOSTING,
      FEATURE_NAME.MANAGED_HOSTING,
      FEATURE_NAME.CUSTOM_DOMAINS,
      FEATURE_NAME.VERSIONING_GIT,
      FEATURE_NAME.CI_CD,
      FEATURE_NAME.GIT_BRANCH_PROTECTION,
      FEATURE_NAME.CUSTOM_ENVIRONMENTS,
      FEATURE_NAME.BACKUP_RESTORE,
      FEATURE_NAME.AIRGAPPED,
      FEATURE_NAME.HIGH_AVAILABILITY,
    ],
  },
  {
    name: FEATURE_SECTION_NAME.ADMIN_SECURE,
    features: [
      FEATURE_NAME.SSO_IDP,
      FEATURE_NAME.AUDIT_LOGS,
      FEATURE_NAME.ACCESS_CONTROLS,
      FEATURE_NAME.SCIM,
      FEATURE_NAME.SOC_2,
    ],
  },
  {
    name: FEATURE_SECTION_NAME.SUPPORT,
    features: [
      FEATURE_NAME.TECH_SUPPORT,
      FEATURE_NAME.APP_DEVELOPMENT,
      FEATURE_NAME.APP_MIGRATION,
    ],
  },
];
