import React from "react";
import { Flex, Spinner, Text } from "@appsmith/ads";

import { LOADING_MEMBERS_MESSAGE } from "constants/Messages";
import { createMessage } from "constants/Messages";

const LoadingMembers = ({ className }: { className?: string }) => {
  return (
    <Flex
      alignItems="center"
      className={className}
      flexDirection="column"
      gap="spaces-3"
      justifyContent="center"
      width="100%"
    >
      <Spinner size="md" />
      <Text kind="body-m">{createMessage(LOADING_MEMBERS_MESSAGE)}</Text>
    </Flex>
  );
};

export { LoadingMembers };
