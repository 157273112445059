import styled from "styled-components";

export const PageContainer = styled.div<{
  hasSidebar?: boolean;
}>`
  padding: 24px;
  margin: 0 auto;
  max-width: ${({ hasSidebar }) => (!hasSidebar ? "1200px" : "1180px")};
  padding-bottom: 24px;
`;

export const PlansOuterWrapper = styled.div`
  max-width: 350px;
  width: 50%;
  box-shadow: 0px -7px 50px rgba(0, 0, 0, 0.1);
  height: 730px;
  border-radius: 16px;
`;
