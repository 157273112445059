import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { createAction } from "utils/ActionUtils";
import { FlowType, LicenseResponseType } from "types/LicenseTypes";

export const fetchLicenseDetails = () =>
  createAction(ReduxActionTypes.FETCH_LICENSE);

export const fetchLicenseDetailsSuccess = (val: LicenseResponseType) =>
  createAction(ReduxActionTypes.FETCH_LICENSE_SUCCESS, val);

export const fetchLicenseDetailsFailed = (val: string) =>
  createAction(ReduxActionTypes.FETCH_LICENSE_FAILED, val);

export const startTrial = () => createAction(ReduxActionTypes.START_TRIAL);

export const startTrialFailed = () =>
  createAction(ReduxActionTypes.START_TRIAL_FAILED);

export const updateFlowType = (val: FlowType) =>
  createAction(ReduxActionTypes.UPDATE_FLOW_TYPE, val);
