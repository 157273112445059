import { all, call } from "redux-saga/effects";
import { LicenseSaga } from "sagas/LicenseSaga";
import { AppSaga } from "./AppSaga";
import { PaymentsSaga } from "./PaymentSaga";
import { UsageSaga } from "./UsageSaga";
import { UserSaga } from "./UserSaga";
import { AccountSaga } from "./AccountSaga";
import { MembersSaga } from "./MembersSaga";
import { SubscriptionSaga } from "./SubscriptionSaga";
import { EnvironmentSaga } from "./EnvironmentSaga";
export function* rootSaga() {
  yield all([
    call(AppSaga),
    call(AccountSaga),
    call(UserSaga),
    call(LicenseSaga),
    call(UsageSaga),
    call(PaymentsSaga),
    call(MembersSaga),
    call(SubscriptionSaga),
    call(EnvironmentSaga),
  ]);
}
