import React from "react";
import { Text } from "@appsmith/ads";
import { createMessage, NO_SEAT_ASSIGNED_TEXT } from "constants/Messages";
import { SEAT_ASSIGNED_TEXT } from "constants/Messages";

type SeatStatusIndicatorProps = {
  seatAssigned: boolean;
  className?: string;
};

const SeatStatusIndicator = ({
  className,
  seatAssigned,
}: SeatStatusIndicatorProps) => {
  return (
    <Text
      className={className}
      color={
        seatAssigned
          ? "var(--ads-v2-color-fg-success)"
          : "var(--ads-v2-color-fg)"
      }
      kind={"body-m"}
    >
      {seatAssigned
        ? createMessage(SEAT_ASSIGNED_TEXT)
        : createMessage(NO_SEAT_ASSIGNED_TEXT)}
    </Text>
  );
};

export { SeatStatusIndicator };
