import { ReduxActionTypes } from "constants/ReduxActionConstants";
import {
  FetchMembersPayload,
  GetMembersSuccessPayload,
  GetMembersCountPayload,
  MembersListStateType,
  Member,
} from "types/MembersTypes";
import { ReduxAction } from "types/ReduxTypes";
import { createReducer } from "utils/ReducerUtils";

export type MembersStateType = {
  members: MembersListStateType;
  purchasedSeats: number;
  occupiedSeats: number;
  totalMembers: number;
  isLoading: boolean;
  error: string | null;
  inviteModalLoading: boolean;
  assignSeatsLoading: boolean;
  unassignSeatsLoading: boolean;
  inviteUsersStatus: Member[];
};

const initialState: MembersStateType = {
  members: {
    members: [],
    paginatedPurchasedSeats: 0,
    paginatedOccupiedSeats: 0,
    paginatedTotalMembers: 0,
  },
  purchasedSeats: 0,
  occupiedSeats: 0,
  totalMembers: 0,
  error: null,
  isLoading: true,
  inviteModalLoading: false,
  assignSeatsLoading: false,
  unassignSeatsLoading: false,
  inviteUsersStatus: [],
};

export const membersReducer = createReducer(initialState, {
  [ReduxActionTypes.FETCH_MEMBERS]: (
    state: MembersStateType,
    action: ReduxAction<FetchMembersPayload>,
  ) => {
    const isLoading = (action.payload?.page ?? 0) > 0 ? state.isLoading : true;

    return {
      ...state,
      isLoading: isLoading,
    };
  },
  [ReduxActionTypes.FETCH_MEMBERS_SUCCESS]: (
    state: MembersStateType,
    action: ReduxAction<GetMembersSuccessPayload>,
  ) => {
    const members =
      action.payload.page === 0
        ? action.payload
        : {
            ...action.payload,
            members: [
              ...(state.members?.members || []),
              ...(action.payload.members || []),
            ],
          };
    return {
      ...state,
      isLoading: false,
      members: members,
    };
  },
  [ReduxActionTypes.FETCH_MEMBERS_FAILED]: (state: MembersStateType) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  [ReduxActionTypes.INVITE_MEMBERS]: (state: MembersStateType) => {
    return {
      ...state,
      inviteModalLoading: true,
    };
  },
  [ReduxActionTypes.INVITE_MEMBERS_SUCCESS]: (
    state: MembersStateType,
    action: ReduxAction<Member[]>,
  ) => {
    return {
      ...state,
      inviteModalLoading: false,
      inviteUsersStatus: action.payload,
    };
  },
  [ReduxActionTypes.INVITE_MEMBERS_FAILED]: (state: MembersStateType) => {
    return {
      ...state,
      inviteModalLoading: false,
      inviteUsersStatus: [],
    };
  },
  [ReduxActionTypes.INVITE_MEMBERS_STATUS_RESET]: (state: MembersStateType) => {
    return {
      ...state,
      inviteUsersStatus: [],
    };
  },
  [ReduxActionTypes.UPDATE_MEMBERS_SUCCESS]: (state: MembersStateType) => {
    return {
      ...state,
      assignSeatsLoading: false,
      unassignSeatsLoading: false,
    };
  },
  [ReduxActionTypes.UPDATE_MEMBERS_FAILED]: (state: MembersStateType) => {
    return {
      ...state,
      assignSeatsLoading: false,
      unassignSeatsLoading: false,
    };
  },
  [ReduxActionTypes.MEMBERS_SET_ASSIGN_SEATS_LOADING]: (
    state: MembersStateType,
    action: ReduxAction<boolean>,
  ) => {
    return {
      ...state,
      assignSeatsLoading: action.payload,
    };
  },
  [ReduxActionTypes.MEMBERS_SET_UNASSIGN_SEATS_LOADING]: (
    state: MembersStateType,
    action: ReduxAction<boolean>,
  ) => {
    return {
      ...state,
      unassignSeatsLoading: action.payload,
    };
  },
  [ReduxActionTypes.FETCH_MEMBERS_COUNT]: (state: MembersStateType) => {
    return {
      ...state,
    };
  },
  [ReduxActionTypes.FETCH_MEMBERS_COUNT_SUCCESS]: (
    state: MembersStateType,
    action: ReduxAction<GetMembersCountPayload>,
  ) => {
    return {
      ...state,
      totalMembers: action.payload.totalMembers,
      purchasedSeats: action.payload.purchasedSeats,
      occupiedSeats: action.payload.occupiedSeats,
    };
  },
});
