import { createMessage, NOT_AVAILABLE } from "constants/Messages";
import { RemainingTime } from "types/LicenseTypes";

import { DEFAULT_DATE_FORMAT } from "constants/DateFormatConstants";
import { dayjs } from "utils/Day-js";

export const getDateString = (
  timestamp?: number,
  format: string = DEFAULT_DATE_FORMAT,
) => {
  if (timestamp) {
    // received timestamp is in seconds, so using dayjs.unix
    return dayjs.unix(timestamp).format(format);
  } else {
    return createMessage(NOT_AVAILABLE);
  }
};

export const getRemainingDays = (timeStamp = Date.now()): RemainingTime => {
  // Ideal behaviour
  // For first 12 hours, it should display 30 days and from 12 - 36 hours, it should display 29 days and so on
  // For last 12 hours, it will display hours left instead of just saying 0 days

  /*

  720 - 708 hrs => 30 days
  708 - 684 hrs => 29 days
  684 - 660 hrs => 28 days
  660 - 636 hrs => 27 days
  ...
  ...
  12 - 0 hrs => X hours

  Formula
  (hrs-12)/24 + 1 = remaining days
  
  Calculations
  (710 - 12)/24 + 1 = 29 + 1 => 30
  (690 - 12)/24 + 1 = 28 + 1 => 29
  (670 - 12)/24 + 1 = 27 + 1 => 28
  (637 - 12)/24 + 1 = 26 + 1 => 27
  (635 - 12)/24 + 1 = 25 + 1 => 26

  */

  // received timestamp is in seconds, so using dayjs.unix
  const totalHours = dayjs.unix(timeStamp).diff(dayjs(), "h");

  if (totalHours <= 720 && totalHours > 708) {
    return {
      count: 30,
      suffix: "days",
    };
  } else if (totalHours <= 12) {
    return {
      count: totalHours,
      suffix: totalHours > 1 ? "hours" : "hour",
    };
  } else {
    const days = Math.floor((totalHours - 12) / 24) + 1;

    return {
      count: days,
      suffix: days > 1 ? "days" : "day",
    };
  }
};

export const getDateRangeString = (startDate: number, endDate: number) => {
  return `${getDateString(startDate)} - ${getDateString(endDate)}`;
};
