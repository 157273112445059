import { AppState } from "reducers";
import { createSelector } from "reselect";

export const getUpcomingInvoiceSummary = (state: AppState) =>
  state.subscription.upcomingInvoiceSummary;

export const getInvoiceSummaryLineItems = createSelector(
  [getUpcomingInvoiceSummary],
  (upcomingInvoiceSummary) => upcomingInvoiceSummary.lineItems,
);

export const getUpcomingInvoiceSummaryIsLoading = (state: AppState) =>
  state.subscription.isUpcomingInvoiceSummaryLoading;

export const getUpcomingInvoiceSummaryTotalDetails = createSelector(
  [getUpcomingInvoiceSummary],
  (upcomingInvoiceSummary) => {
    const { periodEnd, periodStart, total } = upcomingInvoiceSummary;
    return {
      total,
      periodEnd,
      periodStart,
    };
  },
);

export const getUpcomingInvoiceSummaryPeriodEnd = createSelector(
  [getUpcomingInvoiceSummary],
  (upcomingInvoiceSummary) => upcomingInvoiceSummary.periodEnd,
);

export const getUpcomingInvoiceSummaryPeriodStart = createSelector(
  [getUpcomingInvoiceSummary],
  (upcomingInvoiceSummary) => upcomingInvoiceSummary.periodStart,
);

export const getUpcomingInvoiceSummarySubtotal = createSelector(
  [getUpcomingInvoiceSummary],
  (upcomingInvoiceSummary) => upcomingInvoiceSummary.subtotal,
);

export const getUpcomingInvoiceSummaryTotal = createSelector(
  [getUpcomingInvoiceSummary],
  (upcomingInvoiceSummary) => upcomingInvoiceSummary.total,
);

export const getSeatCount = (state: AppState) => state.subscription.seats;

export const getIsUpdateSeatsLoading = (state: AppState) =>
  state.subscription.isUpdateSeatsLoading;

export const getIsProratedInvoiceLoading = (state: AppState) =>
  state.subscription.isProratedInvoiceLoading;

export const getProratedInvoiceLineItems = (state: AppState) =>
  state.subscription.proratedInvoice.lineItems;
