import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { BASE_API_REQUEST_CONFIG } from "constants/ApiConstants";
import {
  convertObjectToQueryParams,
  requestInterceptor,
  responseFailureInterceptor,
  responseSuccessInterceptor,
} from "utils/ApiUtils";

class API {
  private static axiosInstance: AxiosInstance;

  static init() {
    API.axiosInstance = axios.create();

    API.axiosInstance.interceptors.request.use(requestInterceptor as any);

    API.axiosInstance.interceptors.response.use(
      responseSuccessInterceptor,
      responseFailureInterceptor,
    );
  }

  static get(url: string, queryParams?: any, config: AxiosRequestConfig = {}) {
    return API.axiosInstance.get(
      url + convertObjectToQueryParams(queryParams),
      {
        ...BASE_API_REQUEST_CONFIG,
        ...config,
      },
    );
  }

  static post(
    url: string,
    body?: any,
    queryParams?: any,
    config: AxiosRequestConfig = {},
  ) {
    return API.axiosInstance.post(
      url + convertObjectToQueryParams(queryParams),
      body,
      {
        ...BASE_API_REQUEST_CONFIG,
        ...config,
      },
    );
  }

  static put(
    url: string,
    body?: any,
    queryParams?: any,
    config: AxiosRequestConfig = {},
  ) {
    return API.axiosInstance.put(
      url + convertObjectToQueryParams(queryParams),
      body,
      {
        ...BASE_API_REQUEST_CONFIG,
        ...config,
      },
    );
  }
}

export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export default API;
