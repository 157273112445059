import React from "react";
import { Button, ButtonKind } from "@appsmith/ads";

import { ADD_REMOVE_SEATS_CTA } from "constants/Messages";
import { createMessage } from "constants/Messages";
import { useHandleUpgradeClick } from "hooks/useHandleUpgradeClick";
import { useCanModifySeats } from "hooks";
import { useSelector } from "react-redux";
import { shouldShowEnterpriseAccountUsage } from "selectors/AccountSelectors";

const AddOrRemoveSeats = ({
  buttonKind = "secondary",
  className,
}: {
  className?: string;
  buttonKind?: ButtonKind;
}) => {
  const { handleUpgradeClick } = useHandleUpgradeClick();
  const canModifySeats = useCanModifySeats();
  const isEnterprise = useSelector(shouldShowEnterpriseAccountUsage);

  if (isEnterprise) {
    return null;
  }

  return (
    <Button
      className={className}
      isDisabled={!canModifySeats}
      kind={buttonKind}
      onClick={handleUpgradeClick}
      size="md"
    >
      {createMessage(ADD_REMOVE_SEATS_CTA)}
    </Button>
  );
};

export { AddOrRemoveSeats };
