import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  Flex,
  Link,
  SearchInput,
  Table,
  Text,
  Tooltip,
} from "@appsmith/ads";

import { Card, Empty, LoadingMembers, SeatStatusIndicator } from "components";
import {
  ALL_SEATS_ASSIGNED_TOOLTIP,
  createMessage,
  MEMBERS_TEXT,
  PRICING_SUMMARY_PURCHASE_SEATS,
  SEARCH_MEMBERS_PLACEHOLDER,
  SEATS_ASSIGNMENT_TEXT,
  USER_TABLE,
} from "constants/Messages";
import { InviteModal } from "./InviteModal";
import { Member, UpdateMember } from "types/MembersTypes";
import { TableBulkSelectHeader } from "./TableBulkSelectHeader";
import { useMembers } from "hooks";

const MembersList = () => {
  const {
    assignSeatsToMembers,
    isSeatsFullyUtilized,
    memberList,
    membersLoading,
    observableItemRef,
    onInviteMembers,
    paginatedOccupiedSeats,
    paginatedTotalMembers,
    searchTerm,
    setSearchTerm,
    showInfiniteScrollLoading,
    unassignSeatsToMembers,
  } = useMembers();

  const [selectedMembers, setSelectedMembers] = useState<Set<string>>(
    new Set(),
  );

  // reset select state wshen members are loaded
  useEffect(() => {
    setSelectedMembers(new Set());
  }, [memberList]);

  // eligible members to be selected
  const eligibleMembers = useMemo(() => {
    // When all seats are taken, only already-assigned members can be selected
    if (isSeatsFullyUtilized) {
      return memberList.filter((member) => member.seatAssigned === true);
    }
    // When seats are available, all members can be selected
    return memberList;
  }, [memberList, isSeatsFullyUtilized]);

  // Bulk select
  const handleBulkSelect = useCallback(
    (checked: boolean) => {
      const selectedEmails = checked
        ? eligibleMembers.map((member) => member.email)
        : [];

      setSelectedMembers(new Set(selectedEmails));
    },
    [memberList, isSeatsFullyUtilized],
  );

  // Individual select
  const handleMemberSelect = useCallback((email: string, checked: boolean) => {
    setSelectedMembers((prev) => {
      const newSet = new Set(prev);
      checked ? newSet.add(email) : newSet.delete(email);
      return newSet;
    });
  }, []);

  //disable unassign, if all selected memebers are already unassigned
  const isUnassignDisabled = useMemo(() => {
    if (selectedMembers.size === 0) return false;

    const allUnassigned = Array.from(selectedMembers).every(
      (email) =>
        memberList.find((member) => member.email === email)?.seatAssigned ===
        false,
    );

    return allUnassigned;
  }, [selectedMembers, memberList]);

  //disable assign, if all selected memebers are already assigned or seats are fully utilized
  const isAssignDisabled = useMemo(() => {
    if (selectedMembers.size === 0) return false;

    const allAssigned = Array.from(selectedMembers).every(
      (email) =>
        memberList.find((member) => member.email === email)?.seatAssigned ===
        true,
    );

    return allAssigned || isSeatsFullyUtilized;
  }, [selectedMembers, memberList, isSeatsFullyUtilized]);

  // Unassign seats
  const handleUnassignSeats = useCallback(() => {
    const membersToUnassign: UpdateMember[] = Array.from(selectedMembers).map(
      (email) => ({
        email,
        assignSeat: false,
      }),
    );
    unassignSeatsToMembers(membersToUnassign);
  }, [selectedMembers, unassignSeatsToMembers]);

  // Assign seats
  const handleAssignSeats = useCallback(() => {
    const membersToAssign: UpdateMember[] = Array.from(selectedMembers).map(
      (email) => ({
        email,
        assignSeat: true,
      }),
    );
    assignSeatsToMembers(membersToAssign);
  }, [selectedMembers, assignSeatsToMembers]);

  // Handle row click
  const handleRowClick = useCallback(
    (email: string) => {
      // Only allow selection if the seat is assigned or seats are not fully utilized
      const member = memberList.find((m) => m.email === email);
      if (member && (member.seatAssigned || !isSeatsFullyUtilized)) {
        handleMemberSelect(email, !selectedMembers.has(email));
      }
    },
    [memberList, selectedMembers, isSeatsFullyUtilized, handleMemberSelect],
  );

  // Check if all members are selected
  const isAllSelected = useMemo(
    () =>
      eligibleMembers.length > 0 &&
      selectedMembers.size === eligibleMembers.length,
    [eligibleMembers, selectedMembers],
  );

  // Check if some members are selected
  const isIndeterminate = useMemo(
    () => selectedMembers.size > 0 && selectedMembers.size < memberList.length,
    [memberList, selectedMembers],
  );

  // Columns
  const columns = useMemo(
    () => [
      {
        dataIndex: "bulk",
        render: (_: string, record: Member) => {
          const isCheckboxDisabled =
            record.seatAssigned === false && isSeatsFullyUtilized;

          return (
            <Tooltip
              content={createMessage(ALL_SEATS_ASSIGNED_TOOLTIP)}
              isDisabled={!isCheckboxDisabled}
              placement="right"
            >
              <Flex height="16px">
                <Checkbox
                  aria-label={record.email}
                  isDisabled={isCheckboxDisabled}
                  isSelected={selectedMembers.has(record.email)}
                  // onchange is handled in row click
                />
              </Flex>
            </Tooltip>
          );
        },
        title: (
          <Flex height="16px">
            <Checkbox
              aria-label="Select all users"
              isIndeterminate={isIndeterminate}
              isSelected={isAllSelected}
              onChange={handleBulkSelect}
            />
          </Flex>
        ),
        width: "50px",
      },
      { dataIndex: "email", title: createMessage(USER_TABLE.user) },
      { dataIndex: "role", title: createMessage(USER_TABLE.access) },
      {
        dataIndex: "tenantUrl",
        title: createMessage(USER_TABLE.instance),
        render: (tenantUrl: string) => {
          let url = tenantUrl;
          if (
            tenantUrl &&
            !url.startsWith("http://") &&
            !url.startsWith("https://")
          ) {
            url = `https://${url}`;
          }
          return tenantUrl ? <Link to={url}>{tenantUrl}</Link> : "";
        },
      },
      {
        dataIndex: "seatAssigned",
        title: createMessage(USER_TABLE.status),
        render: (seatAssigned: boolean) => (
          <SeatStatusIndicator seatAssigned={seatAssigned} />
        ),
      },
    ],
    [
      handleBulkSelect,
      handleMemberSelect,
      isAllSelected,
      isIndeterminate,
      selectedMembers,
    ],
  );

  return (
    <Card flexDirection="column" gap="spaces-7" overflow="hidden">
      {/* Header */}
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Text kind="heading-m">{createMessage(MEMBERS_TEXT)}</Text>
        <InviteModal onInviteMembers={onInviteMembers} />
      </Flex>

      {/* Members */}
      <Flex flexDirection="column" gap="spaces-4" overflow="hidden">
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <SearchInput
            className="!w-[206px]"
            onChange={(e) => setSearchTerm(e)}
            placeholder={createMessage(SEARCH_MEMBERS_PLACEHOLDER)}
            value={searchTerm}
          />
          <Text kind="body-m">
            {createMessage(
              SEATS_ASSIGNMENT_TEXT,
              paginatedOccupiedSeats,
              paginatedTotalMembers,
            )}
          </Text>
        </Flex>

        <Flex
          border="1px solid var(--ads-v2-color-border)"
          borderRadius="var(--ads-v2-border-radius)"
          flexDirection="column"
          overflowY="auto"
          position={"relative"}
          width="100%"
        >
          <TableBulkSelectHeader
            handleAssignSeats={handleAssignSeats}
            handleUnassignSeats={handleUnassignSeats}
            isAllSelected={isAllSelected}
            isAssignDisabled={isAssignDisabled}
            isIndeterminate={isIndeterminate}
            isSeatsFullyUtilized={isSeatsFullyUtilized}
            isUnassignDisabled={isUnassignDisabled}
            onBulkSelect={handleBulkSelect}
            selectedCount={selectedMembers.size}
          />
          <Table
            columns={columns}
            data={membersLoading ? [] : memberList}
            emptyText={
              membersLoading ? (
                <LoadingMembers />
              ) : (
                <Empty
                  image={"https://assets.appsmith.com/Nomember.svg"}
                  text={createMessage(
                    PRICING_SUMMARY_PURCHASE_SEATS.members_empty_state,
                  )}
                />
              )
            }
            onRow={(record) => ({
              onClick: () => handleRowClick(record.email),
              className: "cursor-pointer",
            })}
            rowHoverable
            sticky
          />
          {showInfiniteScrollLoading && (
            <div
              className="flex justify-center items-center p-[var(--ads-v2-spaces-6)] w-[100%]"
              ref={observableItemRef}
            >
              <LoadingMembers />
            </div>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};

export { MembersList };
