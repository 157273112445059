export const ReduxActionTypes = {
  APP_INIT: "APP_INIT",
  APP_INIT_SUCCESS: "APP_INIT_SUCCESS",

  AUTHENTICATE_USER: "AUTHENTICATE_USER",
  AUTHENTICATE_USER_SUCCESS: "AUTHENTICATE_USER_SUCCESS",
  LOGOUT_USER: "LOGOUT_USER",

  START_TRIAL: "START_TRIAL",
  START_TRIAL_FAILED: "START_TRIAL_FAILED",

  FETCH_ACCOUNT_DETAILS: "FETCH_ACCOUNT_DETAILS",
  FETCH_ACCOUNT_DETAILS_SUCCESS: "FETCH_ACCOUNT_DETAILS_SUCCESS",

  FETCH_LICENSE: "FETCH_LICENSE",
  FETCH_LICENSE_SUCCESS: "FETCH_LICENSE_SUCCESS",
  FETCH_LICENSE_FAILED: "FETCH_LICENSE_FAILED",

  UPDATE_USER_DETAILS: "UPDATE_USER_DETAILS",
  STORE_ACCESS_TOKEN: "STORE_ACCESS_TOKEN",
  FETCH_USER_DETAILS_SUCCESS: "FETCH_USER_DETAILS_SUCCESS",
  FETCH_USER_DETAILS_FAILED: "FETCH_USER_DETAILS_FAILED",

  FETCH_USAGE_DETAILS: "FETCH_USAGE_DETAILS",
  FETCH_USAGE_DETAILS_SUCCESS: "FETCH_USAGE_DETAILS_SUCCESS",
  FETCH_USAGE_DETAILS_FAILURE: "FETCH_USAGE_DETAILS_FAILURE",

  INITIATE_CHECKOUT: "INITIATE_CHECKOUT",
  INITIATE_CHECKOUT_FAILED: "INITIATE_CHECKOUT_FAILED",
  INITIATE_SEAT_BASED_CHECKOUT: "INITIATE_SEAT_BASED_CHECKOUT",
  INITIATE_SEAT_BASED_CHECKOUT_COMPLETED:
    "INITIATE_SEAT_BASED_CHECKOUT_COMPLETED",

  GO_TO_PAYMENT_DASHBOARD: "GO_TO_PAYMENT_DASHBOARD",
  GO_TO_PAYMENT_DASHBOARD_FAILED: "GO_TO_PAYMENT_DASHBOARD_FAILED",

  PAYMENT_SUCCESS_REDIRECT: "PAYMENT_SUCCESS_REDIRECT",

  FETCH_MEMBERS: "FETCH_MEMBERS",
  FETCH_MEMBERS_SUCCESS: "FETCH_MEMBERS_SUCCESS",
  FETCH_MEMBERS_FAILED: "FETCH_MEMBERS_FAILED",
  INVITE_MEMBERS: "INVITE_MEMBERS",
  INVITE_MEMBERS_SUCCESS: "INVITE_MEMBERS_SUCCESS",
  INVITE_MEMBERS_FAILED: "INVITE_MEMBERS_FAILED",
  INVITE_MEMBERS_STATUS_RESET: "INVITE_MEMBERS_STATUS_RESET",
  UPDATE_MEMBERS: "UPDATE_MEMBERS",
  UPDATE_MEMBERS_SUCCESS: "UPDATE_MEMBERS_SUCCESS",
  UPDATE_MEMBERS_FAILED: "UPDATE_MEMBERS_FAILED",
  MEMBERS_SET_ASSIGN_SEATS_LOADING: "MEMBERS_SET_ASSIGN_SEATS_LOADING",
  MEMBERS_SET_UNASSIGN_SEATS_LOADING: "MEMBERS_SET_UNASSIGN_SEATS_LOADING",
  MEMBERS_SET_PAGE_AND_SIZE: "MEMBERS_SET_PAGE_AND_SIZE",
  FETCH_MEMBERS_COUNT: "FETCH_MEMBERS_COUNT",
  FETCH_MEMBERS_COUNT_SUCCESS: "FETCH_MEMBERS_COUNT_SUCCESS",
  FETCH_MEMBERS_COUNT_FAILED: "FETCH_MEMBERS_COUNT_FAILED",

  SET_SUBSCRIPTIONS_UPCOMING_INVOICE_LOADING:
    "SET_SUBSCRIPTIONS_UPCOMING_INVOICE_LOADING",
  FETCH_SUBSCRIPTIONS_UPCOMING_INVOICE_SUMMARY:
    "FETCH_SUBSCRIPTIONS_UPCOMING_INVOICE_SUMMARY",
  FETCH_SUBSCRIPTIONS_UPCOMING_INVOICE_SUMMARY_SUCCESS:
    "FETCH_SUBSCRIPTIONS_UPCOMING_INVOICE_SUMMARY_SUCCESS",
  SET_SUBSCRIPTIONS_SEATS: "SET_SUBSCRIPTIONS_SEATS",
  UPDATE_SUBSCRIPTIONS_SEATS: "UPDATE_SUBSCRIPTIONS_SEATS",
  UPDATE_SUBSCRIPTIONS_SEATS_SUCCESS: "UPDATE_SUBSCRIPTIONS_SEATS_SUCCESS",
  UPDATE_SUBSCRIPTIONS_SEATS_FAILED: "UPDATE_SUBSCRIPTIONS_SEATS_FAILED",
  FETCH_SUBSCRIPTIONS_PRORATED_INVOICE: "FETCH_SUBSCRIPTIONS_PRORATED_INVOICE",
  FETCH_SUBSCRIPTIONS_PRORATED_INVOICE_SUCCESS:
    "FETCH_SUBSCRIPTIONS_PRORATED_INVOICE_SUCCESS",
  FETCH_SUBSCRIPTIONS_PRORATED_INVOICE_FAILED:
    "FETCH_SUBSCRIPTIONS_PRORATED_INVOICE_FAILED",

  UPDATE_FLOW_TYPE: "UPDATE_FLOW_TYPE",

  FETCH_SEAT_BASED_PRICING_ENV_DATA: "FETCH_SEAT_BASED_PRICING_ENV_DATA",
  SET_SEAT_BASED_PRICING_ENV_DATA: "SET_SEAT_BASED_PRICING_ENV_DATA",
};

export const ReduxActionErrorTypes = {};
