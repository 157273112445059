import React from "react";
import { Flex, Text } from "@appsmith/ads";
import { Navigate } from "react-router-dom";

import PlansInfo from "features/plans-info/PlansInfo";
import { PageContainer } from "global-styles/global.styles";
import { FlowType } from "types/LicenseTypes";
import { useSelector } from "react-redux";
import { getFlowType } from "selectors/LicenseSelectors";
import { withAuthentication } from "utils/RouterUtils";
import { withEmailVerification } from "utils/RouterUtils";
import { LICENSE } from "constants/RouteContants";
import { Back } from "components";

const UpgradePlan = () => {
  const flowType = useSelector(getFlowType);

  if (flowType !== FlowType.SEAT_BASED_UPDATE) {
    return <Navigate to={LICENSE} />;
  }

  return (
    <PageContainer hasSidebar={false}>
      <Flex flexDirection="column" gap="spaces-7">
        <Back />
        <Text className="!font-bold" kind="heading-l">
          Upgrade your plan
        </Text>
        <PlansInfo />
      </Flex>
    </PageContainer>
  );
};

export default withAuthentication(withEmailVerification(UpgradePlan));
