import { ApiResponse } from "types/ApiTypes";
import API from "./Api";
import { ENVIRONMENT_SEAT_BASED_PRICING } from "./constants";
import { AxiosPromise } from "axios";
import { SeatBasedPricingEnvDataResponseType } from "types/EnvironmentTypes";

class EnvironmentApi {
  static fetchSeatBasedPricingEnvData(): AxiosPromise<
    ApiResponse<SeatBasedPricingEnvDataResponseType>
  > {
    return API.get(ENVIRONMENT_SEAT_BASED_PRICING);
  }
}

export default EnvironmentApi;
