import { useMemo } from "react";
import { useSelector } from "react-redux";

import { getTotalNumberOfMembers } from "selectors/MembersSelectors";
import { getPurchasedSeatCount } from "selectors/MembersSelectors";
import { FlowType } from "types/LicenseTypes";
import { getFlowType } from "selectors/LicenseSelectors";
import { getSeatBasedPricingEnvData } from "selectors/EnvironmentSelectors";

export const useGetInitialSeatCount = () => {
  const totalMembers = useSelector(getTotalNumberOfMembers);
  const purchasedSeatCount = useSelector(getPurchasedSeatCount);
  const flowType = useSelector(getFlowType);
  const seatBasedPricingEnvData = useSelector(getSeatBasedPricingEnvData);

  const getInitialSeatCount = useMemo(() => {
    let seatCount = Math.min(
      Math.max(totalMembers || 1, seatBasedPricingEnvData.minSeats),
      seatBasedPricingEnvData.maxSeats,
    );
    if (flowType === FlowType.SEAT_BASED_UPDATE) {
      seatCount = purchasedSeatCount || 1;
    }
    return seatCount;
  }, [
    totalMembers,
    purchasedSeatCount,
    flowType,
    seatBasedPricingEnvData.minSeats,
    seatBasedPricingEnvData.maxSeats,
  ]);

  return getInitialSeatCount;
};
