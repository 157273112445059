import React from "react";
import { Flex } from "@appsmith/ads";

interface UsageBarProps {
  usage: number;
  total: number;
}

const UsageBar = ({ total = 0, usage = 0 }: UsageBarProps) => {
  const percentage = (usage / total) * 100 || 0;
  const scale = percentage / 100;

  return (
    <Flex
      bg="var(--ads-v2-color-bg-subtle)"
      borderRadius="8px"
      flexDirection="column"
      h="11px"
      overflow="hidden"
      position="relative"
      w="100%"
    >
      <Flex
        bg={
          percentage < 100
            ? "var(--ads-v2-color-bg-brand)"
            : "var(--ads-v2-color-fg-warning)"
        }
        className="transition-transform duration-[1000ms] ease-in-out origin-left"
        h="100%"
        left="0"
        position="absolute"
        style={{ transform: `scaleX(${scale})` }}
        top="0"
        w="100%"
      />
    </Flex>
  );
};

export { UsageBar };
