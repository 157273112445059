import React from "react";
import Loading from "components/loading/Loading";
import { useSelector } from "react-redux";
import {
  getIsUserEmailVerified,
  isUserAuthenticated,
} from "selectors/UserSelectors";
import { Navigate } from "react-router-dom";
import { VERIFY_EMAIL } from "constants/RouteContants";

export const withAuthentication = (Component: React.ComponentType) => {
  return function AuthenticationWrapper(props: any) {
    const isAuthenticated = useSelector(isUserAuthenticated);

    if (isAuthenticated) {
      return <Component {...props} />;
    } else {
      return <Loading />;
    }
  };
};

export const withEmailVerification = (Component: React.ComponentType) => {
  return function EmailVerificationWrapper(props: any) {
    const isEmailVerified = useSelector(getIsUserEmailVerified);

    if (isEmailVerified) {
      return <Component {...props} />;
    } else {
      return <Navigate to={VERIFY_EMAIL} />;
    }
  };
};
