import React from "react";
import DocumentViewer from "./DocumentViewer";
import { useState } from "react";
import {
  createMessage,
  INSTALLATION_GUIDE_HEADING,
  INSTALL_TEXT,
  OTHER_METHODS,
  UPGRADE_TEXT,
  VISIT_DOCS_LINK_TEXT,
  VISIT_DOCS_TEXT,
} from "constants/Messages";
import {
  DOCUMENTATION_GUIDE_LINK,
  MAIN_TABS,
  SUB_TABS,
} from "constants/InstallationGuideConstants";
import analyticEvents from "constants/AnalyticEvents";
import Analytics from "thirdparty/analytics";
import {
  TabsList,
  Text,
  Tabs,
  Tab,
  SegmentedControl,
  Callout,
} from "@appsmith/ads";
import styled from "styled-components";

export const StyledCallout = styled(Callout)`
  max-width: 780px;
  margin-top: 32px !important;
`;

export default function InstallationAndUpgrade() {
  const [activeMainTab, setActiveMainTab] = useState(MAIN_TABS.DOCKER);
  const [activeSubTab, setActiveSubTab] = useState(SUB_TABS.INSTALL);

  const handleMainTabChanged = (active: MAIN_TABS) => {
    if (active === MAIN_TABS.DOCKER) {
      setActiveMainTab(MAIN_TABS.DOCKER);
      Analytics.track(analyticEvents.VIEW_DOCKER_DOCS);
    } else if (active === MAIN_TABS.KUBERNETES) {
      setActiveMainTab(MAIN_TABS.KUBERNETES);
      Analytics.track(analyticEvents.VIEW_KUBERNETES_DOCS);
    } else if (active === MAIN_TABS.OTHER) {
      setActiveMainTab(MAIN_TABS.OTHER);
      Analytics.track(analyticEvents.VIEW_OTHER_DOCS);
    }
  };

  const handleSubTabChanged = (active: SUB_TABS) => {
    if (active === SUB_TABS.INSTALL) {
      setActiveSubTab(SUB_TABS.INSTALL);
      if (activeMainTab === MAIN_TABS.DOCKER) {
        Analytics.track(analyticEvents.VIEW_DOCKER_INSTALL);
      } else {
        Analytics.track(analyticEvents.VIEW_KUBERNETES_INSTALL);
      }
    } else if (active === SUB_TABS.UPGRADE) {
      setActiveSubTab(SUB_TABS.UPGRADE);
      if (activeMainTab === MAIN_TABS.DOCKER) {
        Analytics.track(analyticEvents.VIEW_DOCKER_UPGRADE);
      } else {
        Analytics.track(analyticEvents.VIEW_KUBERNETES_UPGRADE);
      }
    }
  };

  return (
    <div className="mt-8 mb-8">
      <Text className="!font-semibold" kind="heading-s">
        {createMessage(INSTALLATION_GUIDE_HEADING)}
      </Text>
      <div className="mt-3 mb-4">
        <Tabs
          onValueChange={(active) => handleMainTabChanged(active as MAIN_TABS)}
          value={activeMainTab}
        >
          <TabsList>
            <Tab data-testid="docker-tab" value={MAIN_TABS.DOCKER}>
              Docker
            </Tab>
            <Tab data-testid="kubernetes-tab" value={MAIN_TABS.KUBERNETES}>
              Kubernetes
            </Tab>
            <Tab data-testid="others-tab" value={MAIN_TABS.OTHER}>
              {createMessage(OTHER_METHODS)}
            </Tab>
          </TabsList>
        </Tabs>
      </div>
      {activeMainTab !== MAIN_TABS.OTHER && (
        <SegmentedControl
          className="mb-4"
          defaultValue={SUB_TABS.INSTALL}
          isFullWidth={false}
          onChange={(active) => handleSubTabChanged(active as SUB_TABS)}
          options={[
            {
              label: createMessage(INSTALL_TEXT),
              value: SUB_TABS.INSTALL,
            },
            {
              label: createMessage(UPGRADE_TEXT),
              value: SUB_TABS.UPGRADE,
            },
          ]}
        />
      )}
      <DocumentViewer
        activeMainTab={activeMainTab}
        activeSubTab={activeSubTab}
      />
      {activeMainTab !== MAIN_TABS.OTHER && (
        <StyledCallout
          links={[
            {
              children: createMessage(VISIT_DOCS_LINK_TEXT),
              target: "_blank",
              to: DOCUMENTATION_GUIDE_LINK,
            },
          ]}
        >
          {createMessage(VISIT_DOCS_TEXT)}
        </StyledCallout>
      )}
    </div>
  );
}
