import { setSeatBasedPricingEnvData } from "actions/EnvironmentActions";
import EnvironmentApi from "api/EnvironmentApi";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { call, put, takeLatest } from "redux-saga/effects";
import { ApiResponse } from "types/ApiTypes";
import { SeatBasedPricingEnvDataResponseType } from "types/EnvironmentTypes";
import { getErrorMessage, isValidResponse, showToaster } from "./utilitySaga";
import log from "loglevel";
import {
  createMessage,
  SEAT_BASED_PRICING_ENV_DATA_FETCH_ERROR,
} from "constants/Messages";

function* onFetchSeatBasedPricingEnvData() {
  try {
    const response: ApiResponse<SeatBasedPricingEnvDataResponseType> =
      yield call(EnvironmentApi.fetchSeatBasedPricingEnvData);

    const isValid: boolean = yield call(isValidResponse, response);

    if (isValid) {
      yield put(setSeatBasedPricingEnvData(response.data));
    } else {
      throw new Error(createMessage(SEAT_BASED_PRICING_ENV_DATA_FETCH_ERROR));
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    log.error(errorMessage);
    yield call(showToaster, errorMessage);
  }
}

export function* EnvironmentSaga() {
  yield takeLatest(
    ReduxActionTypes.FETCH_SEAT_BASED_PRICING_ENV_DATA,
    onFetchSeatBasedPricingEnvData,
  );
}
