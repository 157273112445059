import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { SeatBasedPricingEnvDataResponseType } from "types/EnvironmentTypes";
import { ReduxAction } from "types/ReduxTypes";
import { createReducer } from "utils/ReducerUtils";

export type EnvironmentStateType = {
  seatBased: SeatBasedPricingEnvDataResponseType;
};

const initialState: EnvironmentStateType = {
  seatBased: {
    maxSeats: 0,
    pricePerSeat: 0,
    minSeats: 0,
  },
};

export const environmentReducer = createReducer(initialState, {
  [ReduxActionTypes.SET_SEAT_BASED_PRICING_ENV_DATA]: (
    state: EnvironmentStateType,
    action: ReduxAction<EnvironmentStateType>,
  ) => {
    return { ...state, seatBased: action.payload };
  },
});
