import React, { useCallback, useEffect, useState } from "react";
import { Button, Flex, Text } from "@appsmith/ads";
import { useSelector } from "react-redux";

import { AddOrRemoveSeats, Card } from "components";
import {
  SEAT_BASED_BILLING_MANAGE_YOUR_BILLING_TEXT,
  SEAT_BASED_BILLING_PRICE_PER_MONTH_TEXT,
  SEAT_BASED_BILLING_RENEWAL_DATE_TEXT,
  SEAT_BASED_BILLING_TOTAL_SEATS_TEXT,
  SEAT_BASED_BILLING_UPGRADE_CTA,
} from "constants/Messages";
import { SEAT_BASED_BILLING_PLAN_TEXT } from "constants/Messages";
import { createMessage } from "constants/Messages";
import {
  getUpcomingInvoiceSummary,
  getUpcomingInvoiceSummaryPeriodEnd,
} from "selectors/SubscriptionSelectors";
import { getDateString } from "utils/CommonUtils";
import ManageYourBilling from "features/usage-details/ManageYourBIlling";
import { useNavigate } from "react-router-dom";
import { UPGRADE_PLAN } from "constants/RouteContants";
import { useCanModifySeats } from "hooks";

const PlanCard = () => {
  const navigate = useNavigate();
  const upcomingInvoiceSummary = useSelector(getUpcomingInvoiceSummary);
  const [pricePerSeat, setPricePerSeat] = useState(0);
  const [totalSeats, setTotalSeats] = useState(0);
  const periodEnd = useSelector(getUpcomingInvoiceSummaryPeriodEnd);
  const canModifySeats = useCanModifySeats();

  useEffect(() => {
    if (upcomingInvoiceSummary) {
      // loop through line items and get the price per seat from the item which has isProrated set to false
      const _pricePerSeat = upcomingInvoiceSummary.lineItems.find(
        (item) => !item.isProrated,
      )?.unitPrice;
      setPricePerSeat(_pricePerSeat || 0);

      const _totalSeats = upcomingInvoiceSummary.lineItems.find(
        (item) => !item.isProrated,
      )?.quantity;
      setTotalSeats(_totalSeats || 0);
    }
  }, [upcomingInvoiceSummary]);

  const handleUpgrade = useCallback(() => {
    navigate(UPGRADE_PLAN);
  }, [navigate]);

  return (
    <Card flexDirection="column" gap="spaces-7">
      <Flex alignItems="center" justifyContent="space-between">
        {/* title plus upgrade */}
        <Flex alignItems="center" gap="spaces-2" justifyContent="center">
          <Text kind="heading-m">
            {createMessage(SEAT_BASED_BILLING_PLAN_TEXT)}
          </Text>
          <Button
            isDisabled={!canModifySeats}
            kind="secondary"
            onClick={handleUpgrade}
            size="sm"
          >
            {createMessage(SEAT_BASED_BILLING_UPGRADE_CTA)}
          </Button>
        </Flex>

        <Text kind="heading-xs">
          {createMessage(SEAT_BASED_BILLING_PRICE_PER_MONTH_TEXT, pricePerSeat)}
        </Text>

        <Text kind="heading-xs">
          {createMessage(SEAT_BASED_BILLING_TOTAL_SEATS_TEXT, totalSeats)}
        </Text>

        <Text kind="heading-xs">
          {createMessage(
            SEAT_BASED_BILLING_RENEWAL_DATE_TEXT,
            periodEnd ? getDateString(periodEnd, "MMM Do, YYYY") : "",
          )}
        </Text>

        <AddOrRemoveSeats />
      </Flex>
      <ManageYourBilling title={SEAT_BASED_BILLING_MANAGE_YOUR_BILLING_TEXT} />
    </Card>
  );
};

export { PlanCard };
