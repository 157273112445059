import { all, call, put, takeLatest } from "redux-saga/effects";
import log from "loglevel";

import { getErrorMessage, showToaster } from "./utilitySaga";
import { isValidResponse } from "./utilitySaga";
import {
  createMessage,
  INVITE_MEMBERS_FAILED_MESSAGE,
  INVITE_MEMBERS_SUCCESS_MESSAGE,
  MEMBERS_FETCH_ERROR,
  UPDATE_MEMBERS_FAILED_MESSAGE,
  UPDATE_MEMBERS_SUCCESS_MESSAGE,
} from "constants/Messages";
import { ApiResponse } from "types/ApiTypes";
import {
  FetchMembersPayload,
  GetMembersCountPayload,
  InviteMembersPayload,
  Member,
  MembersResponseType,
  UpdateMembersPayload,
} from "types/MembersTypes";
import MembersApi from "api/MembersApi";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import {
  fetchMembers,
  fetchMembersCount,
  fetchMembersCountFailed,
  fetchMembersCountSuccess,
  fetchMembersFailed,
  fetchMembersSuccess,
  inviteMembersFailed,
  inviteMembersSuccess,
  updateMembersFailed,
  updateMembersSuccess,
} from "actions/MembersActions";
import { ReduxAction } from "types/ReduxTypes";

export function* onFetchMembers(action?: ReduxAction<FetchMembersPayload>) {
  try {
    const response: ApiResponse<MembersResponseType> = yield call(
      MembersApi.fetchMembers,
      action?.payload,
    );
    const isValid: boolean = yield call(isValidResponse, response);
    if (isValid) {
      yield put(
        fetchMembersSuccess({
          members: response.data.members,
          paginatedPurchasedSeats: response.data.purchasedSeats,
          paginatedOccupiedSeats: response.data.occupiedSeats,
          paginatedTotalMembers: response.data.totalMembers,
          page: action?.payload?.page ?? 0,
          email: action?.payload?.email ?? "",
        }),
      );
    } else {
      throw new Error(createMessage(MEMBERS_FETCH_ERROR));
    }
  } catch (error: any) {
    log.error(error);
    const errorMsg = getErrorMessage(error);
    yield call(showToaster, errorMsg);
    yield put(fetchMembersFailed(errorMsg));
  }
}

export function* onInviteMembers(action: ReduxAction<InviteMembersPayload>) {
  try {
    const response: ApiResponse<Member[]> = yield call(
      MembersApi.inviteMembers,
      action.payload.emails,
    );
    const isValid: boolean = yield call(isValidResponse, response);
    if (isValid) {
      yield put(inviteMembersSuccess(response.data));
      yield call(
        showToaster,
        createMessage(INVITE_MEMBERS_SUCCESS_MESSAGE),
        "success",
      );
      yield put(
        fetchMembers({
          page: action.payload.page ?? 0,
          email: action.payload.email ?? "",
        }),
      );
    } else {
      yield put(
        inviteMembersFailed(createMessage(INVITE_MEMBERS_FAILED_MESSAGE)),
      );
      throw new Error(createMessage(INVITE_MEMBERS_FAILED_MESSAGE));
    }
  } catch (error: any) {
    log.error(error);
    const errorMsg = getErrorMessage(error);
    yield call(showToaster, errorMsg);
    yield put(inviteMembersFailed(errorMsg));
  }
}

export function* onUpdateMembers(action: ReduxAction<UpdateMembersPayload>) {
  try {
    const response: ApiResponse<MembersResponseType> = yield call(
      MembersApi.updateMembers,
      action.payload.members,
    );
    const isValid: boolean = yield call(isValidResponse, response);
    if (isValid) {
      yield put(updateMembersSuccess());
      yield call(
        showToaster,
        createMessage(UPDATE_MEMBERS_SUCCESS_MESSAGE),
        "success",
      );
      yield put(
        fetchMembers({
          page: action.payload.page ?? 0,
          email: action.payload.email ?? "",
        }),
      );
      yield put(fetchMembersCount());
    } else {
      throw new Error(createMessage(UPDATE_MEMBERS_FAILED_MESSAGE));
    }
  } catch (error: any) {
    log.error(error);
    const errorMsg = getErrorMessage(error);
    yield call(showToaster, errorMsg);
    yield put(updateMembersFailed());
  }
}

export function* onFetchMembersCount() {
  try {
    const response: ApiResponse<GetMembersCountPayload> = yield call(
      MembersApi.getMembersCount,
    );
    const isValid: boolean = yield call(isValidResponse, response);
    if (isValid) {
      yield put(fetchMembersCountSuccess(response.data));
    } else {
      throw new Error(createMessage(MEMBERS_FETCH_ERROR));
    }
  } catch (error: any) {
    log.error(error);
    const errorMsg = getErrorMessage(error);
    yield call(showToaster, errorMsg);
    yield put(fetchMembersCountFailed(errorMsg));
  }
}

export function* MembersSaga() {
  yield all([
    takeLatest(ReduxActionTypes.FETCH_MEMBERS, onFetchMembers),
    takeLatest(ReduxActionTypes.INVITE_MEMBERS, onInviteMembers),
    takeLatest(ReduxActionTypes.UPDATE_MEMBERS, onUpdateMembers),
    takeLatest(ReduxActionTypes.FETCH_MEMBERS_COUNT, onFetchMembersCount),
  ]);
}
