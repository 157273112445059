import React from "react";
import Loading from "components/loading/Loading";
import {
  createMessage,
  ACTIVE_USERS,
  SALES_EMAIL,
  REACH_OUT,
  HOURS_USED,
  SELF_SERVE_HOURS_USED_TOOLTIP,
  SELF_SERVE_ACTIVE_USERS_TOOLTIP,
  HOURS_BILLED,
  SELF_SERVE_PROGRESS_LEGEND_OVERAGE,
  SELF_SERVE_PROGRESS_LEGEND_PREPAID_TOOLTIP,
  SELF_SERVE_PROGRESS_LEGEND_PREPAID,
  SELF_SERVE_PROGRESS_LEGEND_OVERAGE_TOOLTIP,
  MANAGE_YOUR_BILLING,
} from "constants/Messages";
import { Text } from "@appsmith/ads";
import {
  InnerBody,
  Divider,
  StyledLink,
  HGap,
  LegendFg1,
  LegendFg2,
} from "../common.styles";
import { UsageError } from "../UsageError";
import { useSelector } from "react-redux";
import {
  getIsUsageLoading,
  getIsUsageAvailable,
  getSelfServeUsageDetails,
} from "selectors/UsageSelectors";
import ManageYourBilling from "../ManageYourBIlling";
import TextWithTooltip from "../TextWithTooltip";
import ProgressBar from "../ProgressBar";
import { APPSMITH_SALES_EMAIL } from "constants/CommonConstant";

export default function SelfServeUsageDetails() {
  const isLoading = useSelector(getIsUsageLoading);
  const isUsageAvailable = useSelector(getIsUsageAvailable);
  const usage = useSelector(getSelfServeUsageDetails);
  const mailTo = `mailto:${APPSMITH_SALES_EMAIL}`;

  const prepaidProgress = (usage.prepaidHours / usage.hoursBilled) * 100;
  const overageProgress = (usage.overageHours / usage.hoursBilled) * 100;

  return (
    <InnerBody>
      {isLoading || !isUsageAvailable ? (
        isLoading ? (
          <Loading inline />
        ) : (
          <UsageError />
        )
      ) : (
        <div data-testid="t--selfserve-usage-details">
          <div className="flex items-center">
            <TextWithTooltip
              data-testid="t--selfserve-usage-hours-used"
              text={createMessage(HOURS_USED)}
              tooltipText={createMessage(SELF_SERVE_HOURS_USED_TOOLTIP)}
              value={usage?.hoursUsed ?? 0}
            />
            <HGap />
            <TextWithTooltip
              data-testid="t--selfserve-usage-active-users"
              text={createMessage(ACTIVE_USERS)}
              tooltipText={createMessage(SELF_SERVE_ACTIVE_USERS_TOOLTIP)}
              value={usage?.activeUsers ?? 0}
            />
          </div>

          <Divider />

          <div className="mb-9">
            <div
              className="mb-3 flex justify-between"
              data-testid="t--selfserve-usage-hours-billed"
            >
              <TextWithTooltip text={createMessage(HOURS_BILLED)} />
              <Text className="!font-medium">{usage.hoursBilled}</Text>
            </div>
            <ProgressBar
              fg1Progress={prepaidProgress}
              fg2Progress={overageProgress}
            />
            <div className="flex">
              <div className="flex items-center mr-5">
                <LegendFg1 className="mr-1" />
                <TextWithTooltip
                  data-testid="t--selfserve-usage-legend-prepaid"
                  small
                  text={createMessage(
                    SELF_SERVE_PROGRESS_LEGEND_PREPAID,
                    usage.prepaidHours,
                  )}
                  tooltipText={createMessage(
                    SELF_SERVE_PROGRESS_LEGEND_PREPAID_TOOLTIP,
                  )}
                />
              </div>
              <div className="flex items-center">
                <LegendFg2 className="mr-1" />
                <TextWithTooltip
                  data-testid="t--selfserve-usage-legend-overage"
                  small
                  text={createMessage(
                    SELF_SERVE_PROGRESS_LEGEND_OVERAGE,
                    usage.overageHours,
                  )}
                  tooltipText={createMessage(
                    SELF_SERVE_PROGRESS_LEGEND_OVERAGE_TOOLTIP,
                  )}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between items-end">
            <ManageYourBilling title={MANAGE_YOUR_BILLING} />
            <div className="shrink-0">
              <Text renderAs="span">{createMessage(REACH_OUT)} </Text>
              <StyledLink
                data-testid="t--selfserve-usage-contact-sales-link"
                kind="secondary"
                to={mailTo}
              >
                {createMessage(SALES_EMAIL)}
              </StyledLink>
            </div>
          </div>
        </div>
      )}
    </InnerBody>
  );
}
