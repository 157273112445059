import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Back } from "components";
import { PageContainer } from "global-styles/global.styles";
import { withAuthentication, withEmailVerification } from "utils/RouterUtils";
import { Container, Header, Content, Summary } from "./components";
import {
  createMessage,
  PRICING_SUMMARY_PURCHASE_SEATS,
} from "constants/Messages";
import { Navigate } from "react-router-dom";
import { FlowType } from "types/LicenseTypes";
import { getFlowType } from "selectors/LicenseSelectors";
import { LICENSE } from "constants/RouteContants";
import { getSeatBasedPricingEnvData } from "selectors/EnvironmentSelectors";
import { fetchMembersCount } from "actions/MembersActions";
import { shouldShowEnterpriseAccountUsage } from "selectors/AccountSelectors";

function PricingSummary() {
  const dispatch = useDispatch();
  const flowType = useSelector(getFlowType);
  const seatBasedPricingEnvData = useSelector(getSeatBasedPricingEnvData);
  const isEnterprise = useSelector(shouldShowEnterpriseAccountUsage);

  useEffect(() => {
    dispatch(fetchMembersCount());
  }, []);

  if (flowType === FlowType.USAGE_BASED || isEnterprise) {
    return <Navigate to={LICENSE} />;
  }

  return (
    <PageContainer hasSidebar={false}>
      <Container>
        <Back />
        <Header
          heading={createMessage(PRICING_SUMMARY_PURCHASE_SEATS.heading)}
          subHeading={createMessage(
            PRICING_SUMMARY_PURCHASE_SEATS.description,
            seatBasedPricingEnvData.minSeats,
          )}
        />
        <Content />
        <Summary />
      </Container>
    </PageContainer>
  );
}

export default withAuthentication(withEmailVerification(PricingSummary));
