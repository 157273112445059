import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { createAction } from "utils/ActionUtils";

export const initiateCheckout = () =>
  createAction(ReduxActionTypes.INITIATE_CHECKOUT);

export const initiateCheckoutFailed = () =>
  createAction(ReduxActionTypes.INITIATE_CHECKOUT_FAILED);

export const goToPaymentDashboard = () =>
  createAction(ReduxActionTypes.GO_TO_PAYMENT_DASHBOARD);

export const goToPaymentDashboardFailed = () =>
  createAction(ReduxActionTypes.GO_TO_PAYMENT_DASHBOARD_FAILED);

export const paymentSuccessRedirect = (val: boolean) =>
  createAction(ReduxActionTypes.PAYMENT_SUCCESS_REDIRECT, val);

export const initiateSeatBasedCheckout = (seatCount: number) =>
  createAction(ReduxActionTypes.INITIATE_SEAT_BASED_CHECKOUT, seatCount);

export const initiateSeatBasedCheckoutCompleted = () =>
  createAction(ReduxActionTypes.INITIATE_SEAT_BASED_CHECKOUT_COMPLETED);
