import React from "react";
import {
  DateHeading,
  Header,
  StyledDivTag,
  StyledDivTagWrapper,
  UsageCard,
} from "./EnterpriseUsage.styles";
import { createMessage, USAGE_TEXT } from "constants/Messages";
import { useSelector } from "react-redux";
import { getEnterpriseDate } from "selectors/UsageSelectors";
import { Icon, Tag, Text } from "@appsmith/ads";
import { getDateString } from "utils/CommonUtils";
import { getLicensePlanText } from "utils/LicenseUtils";
import EnterpriseUsageDetails from "./EnterpriseUsageDetails";
import { getLicensePlan, getProductEdition } from "selectors/LicenseSelectors";

function EnterpriseUsage() {
  const enterpriseDate = useSelector(getEnterpriseDate);
  const licensePlan = useSelector(getLicensePlan);
  const productEdition = useSelector(getProductEdition);

  return (
    <UsageCard data-testid="t--enterprise-usage-card">
      <Header>
        <Icon
          className="mt-1"
          color="var(--ads-v2-color-fg)"
          name="money-dollar-circle-line"
          size="lg"
        />
        <DateHeading>
          <StyledDivTagWrapper>
            <Text className="!font-semibold" kind="heading-m">
              {createMessage(USAGE_TEXT)}
            </Text>
            <StyledDivTag>
              <Tag isClosable={false}>
                {getLicensePlanText(licensePlan, productEdition)}
              </Tag>
            </StyledDivTag>
          </StyledDivTagWrapper>

          {enterpriseDate &&
            !!enterpriseDate.startDate &&
            !!enterpriseDate.endDate && (
              <Text data-testid="usage-date" kind="body-m">
                {getDateString(enterpriseDate.startDate)}
                <span> - </span>
                {getDateString(enterpriseDate.endDate)}
              </Text>
            )}
        </DateHeading>
      </Header>
      <EnterpriseUsageDetails />
    </UsageCard>
  );
}

export default EnterpriseUsage;
