import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Text, Divider, Button, TextProps, Spinner } from "@appsmith/ads";

import { Card } from "components";
import {
  createMessage,
  CURRENCY_FORMAT,
  PRICING_SUMMARY_PURCHASE_SEATS,
} from "constants/Messages";
import { getPurchasedSeatCount } from "selectors/MembersSelectors";
import { updateSeats } from "actions/SubscriptionActions";
import {
  getInvoiceSummaryLineItems,
  getIsProratedInvoiceLoading,
  getIsUpdateSeatsLoading,
  getProratedInvoiceLineItems,
  getSeatCount,
  getUpcomingInvoiceSummaryIsLoading,
  getUpcomingInvoiceSummaryTotalDetails,
} from "selectors/SubscriptionSelectors";
import { getDateRangeString } from "utils/CommonUtils";
import { initiateSeatBasedCheckout } from "actions/paymentActions";
import {
  getIsBeingRedirectedToCheckout,
  getIsBeingRedirectedToSeatBasedCheckout,
} from "selectors/PaymentsSelectors";
import { getFlowType } from "selectors/LicenseSelectors";
import { FlowType } from "types/LicenseTypes";
import { getLicenseAccountStatus } from "selectors/AccountSelectors";
import { ACCOUNT_STATUS_TYPES } from "constants/AccountTypeConstants";
import { AppState } from "reducers";
import {
  UpcomingInvoiceSummaryResponseType,
  UpcomingInvoiceLineItemType,
  ProratedInvoiceLineItemType,
} from "types/SubscriptionTypes";

// summary item component
const SummaryItem = React.memo(
  ({
    price,
    subTitle,
    title,
    titleKind = "heading-xs",
  }: {
    subTitle?: string;
    price: number;
    title: string;
    titleKind?: TextProps["kind"];
  }) => {
    return (
      <Flex
        className="summary-item"
        flexDirection="row"
        gap="spaces-2"
        justifyContent="space-between"
        w="100%"
      >
        {/* Left side */}
        <Flex flexDirection="column" gap="spaces-2" w="226px">
          <Text kind={titleKind}>{title}</Text>
          {subTitle && <Text kind="body-s">{subTitle}</Text>}
        </Flex>
        {/* Right side */}
        <Text kind="heading-xs">{createMessage(CURRENCY_FORMAT, price)}</Text>
      </Flex>
    );
  },
);

// loading component
const LoadingSpinner = React.memo(() => (
  <Flex alignItems="center" h="100%" justifyContent="center">
    <Spinner size="md" />
  </Flex>
));

// Consolidated SummaryItemList component
const SummaryItemList = React.memo(({ flowType }: { flowType: FlowType }) => {
  const { purchasedSeatCount, subscriptionSeatCount } = useSelector(
    (state: AppState) => ({
      purchasedSeatCount: getPurchasedSeatCount(state),
      subscriptionSeatCount: getSeatCount(state),
    }),
  );

  const items = useSelector(
    flowType === FlowType.SEAT_BASED
      ? getInvoiceSummaryLineItems
      : getProratedInvoiceLineItems,
  ) as (UpcomingInvoiceLineItemType | ProratedInvoiceLineItemType)[];

  if (
    flowType === FlowType.SEAT_BASED_UPDATE &&
    items.length === 0 &&
    purchasedSeatCount &&
    subscriptionSeatCount < purchasedSeatCount
  ) {
    return (
      <Flex className="summary-items" flexDirection="column" gap="spaces-6">
        <SummaryItem
          price={0}
          subTitle={createMessage(
            PRICING_SUMMARY_PURCHASE_SEATS.sumary_seat_removed_subtext,
          )}
          title={createMessage(
            PRICING_SUMMARY_PURCHASE_SEATS.summary_seats_removed,
            purchasedSeatCount - subscriptionSeatCount,
          )}
        />
      </Flex>
    );
  }

  if (flowType === FlowType.SEAT_BASED_UPDATE && items.length === 0) {
    return (
      <Flex className="summary-items" flexDirection="column" gap="spaces-6">
        <SummaryItem
          price={0}
          title={createMessage(PRICING_SUMMARY_PURCHASE_SEATS.summary_no_seats)}
        />
      </Flex>
    );
  }

  return (
    <Flex className="summary-items" flexDirection="column" gap="spaces-6">
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <SummaryItem
            price={item.amount}
            subTitle={
              "period" in item
                ? getDateRangeString(item.period.start, item.period.end)
                : undefined
            }
            title={item.description}
          />
          {(flowType === FlowType.SEAT_BASED || index !== items.length - 1) && (
            <Divider />
          )}
        </React.Fragment>
      ))}
    </Flex>
  );
});

// Memoized Summary content
const SummaryContent = React.memo(
  ({
    flowType,
    handleUpgradeClick,
    isBeingRedirectedToSeatBasedCheckout,
    isButtonDisabled,
    isUpdateSeatsLoading,
    totalDetails,
  }: {
    flowType: FlowType;
    handleUpgradeClick: () => void;
    isBeingRedirectedToSeatBasedCheckout: boolean;
    isButtonDisabled: boolean;
    isUpdateSeatsLoading: boolean;
    totalDetails: UpcomingInvoiceSummaryResponseType;
  }) => (
    <>
      <Text kind="heading-l">
        {flowType === FlowType.SEAT_BASED
          ? createMessage(PRICING_SUMMARY_PURCHASE_SEATS.summary_heading)
          : createMessage(
              PRICING_SUMMARY_PURCHASE_SEATS.summary_heading_prorated,
            )}
      </Text>

      <SummaryItemList flowType={flowType} />

      {flowType === FlowType.SEAT_BASED && (
        <SummaryItem
          price={totalDetails.total || 0}
          title={createMessage(
            PRICING_SUMMARY_PURCHASE_SEATS.summary_total,
            "today",
          )}
          titleKind="heading-s"
        />
      )}

      <Button
        isDisabled={isButtonDisabled}
        isLoading={isUpdateSeatsLoading || isBeingRedirectedToSeatBasedCheckout}
        kind="primary"
        onClick={handleUpgradeClick}
        size="md"
      >
        {flowType === FlowType.SEAT_BASED_UPDATE
          ? createMessage(
              PRICING_SUMMARY_PURCHASE_SEATS.summary_pay_now_add_remove_seats,
            )
          : createMessage(PRICING_SUMMARY_PURCHASE_SEATS.summary_pay_now)}
      </Button>
    </>
  ),
);

const Summary = () => {
  const dispatch = useDispatch();

  // Group related selectors
  const {
    accountStatus,
    flowType,
    purchasedSeatCount,
    subscriptionSeatCount,
    totalDetails,
  } = useSelector((state: AppState) => ({
    purchasedSeatCount: getPurchasedSeatCount(state),
    subscriptionSeatCount: getSeatCount(state),
    flowType: getFlowType(state),
    accountStatus: getLicenseAccountStatus(state),
    totalDetails: getUpcomingInvoiceSummaryTotalDetails(state),
  }));

  // Group loading states
  const isLoading = useSelector(
    (state: AppState) =>
      getUpcomingInvoiceSummaryIsLoading(state) ||
      getIsBeingRedirectedToCheckout(state) ||
      getIsProratedInvoiceLoading(state),
  );

  const isUpdateSeatsLoading = useSelector(getIsUpdateSeatsLoading);
  const isBeingRedirectedToSeatBasedCheckout = useSelector(
    getIsBeingRedirectedToSeatBasedCheckout,
  );

  const isButtonDisabled = useMemo(
    () => purchasedSeatCount === subscriptionSeatCount,
    [purchasedSeatCount, subscriptionSeatCount],
  );

  const handleUpgradeClick = useCallback(() => {
    if (
      [
        ACCOUNT_STATUS_TYPES.TRIAL_ACTIVE,
        ACCOUNT_STATUS_TYPES.TRIAL_EXPIRED,
        ACCOUNT_STATUS_TYPES.PAID_EXPIRED,
      ].includes(accountStatus as ACCOUNT_STATUS_TYPES)
    ) {
      dispatch(initiateSeatBasedCheckout(subscriptionSeatCount));
    } else if (accountStatus === ACCOUNT_STATUS_TYPES.PAID_ACTIVE) {
      dispatch(updateSeats(subscriptionSeatCount));
    }
  }, [dispatch, subscriptionSeatCount, accountStatus]);

  return (
    <Card
      flexDirection="column"
      gap="spaces-6"
      gridArea="summary"
      h="fit-content"
      position="sticky"
      top="8px"
      w="400px"
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <SummaryContent
          flowType={flowType}
          handleUpgradeClick={handleUpgradeClick}
          isBeingRedirectedToSeatBasedCheckout={
            isBeingRedirectedToSeatBasedCheckout
          }
          isButtonDisabled={isButtonDisabled}
          isUpdateSeatsLoading={isUpdateSeatsLoading}
          totalDetails={totalDetails as UpcomingInvoiceSummaryResponseType}
        />
      )}
    </Card>
  );
};

export { Summary };
