import { ACCOUNT_STATUS_TYPES } from "constants/AccountTypeConstants";
import {
  createMessage,
  ENTERPRISE_CONTACT_MESSAGE_INTERCOM,
} from "constants/Messages";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import {
  getAccountType,
  isEnterpriseAccount,
} from "selectors/AccountSelectors";
import {
  startConversationOnIntercom,
  textForIntercomMessage,
} from "utils/IntercomUtils";

export const useShowIntercomWithText = () => {
  const accountType = useSelector(getAccountType);
  const isEnterprise = useSelector(isEnterpriseAccount);

  const showIntercomWithText = useCallback(() => {
    let message = textForIntercomMessage(
      accountType as ACCOUNT_STATUS_TYPES,
      isEnterprise,
    );
    if (!message) {
      message = createMessage(ENTERPRISE_CONTACT_MESSAGE_INTERCOM);
    }
    startConversationOnIntercom(message);
  }, [accountType, isEnterprise]);

  return { showIntercomWithText };
};
