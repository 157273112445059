import React from "react";
import CommandBox from "../CommandBox";
import { Step } from "../DocumentViewer";
import {
  GuideWrapper,
  RedStyling,
  BlueStyling,
  PinkStyling,
  Comment,
} from "../DocumentViewer.styles";
import { Text } from "@appsmith/ads";

function KubernetesUpgradeGuide() {
  return (
    <>
      <Text data-testid="kubernetes-upgrade-guide" kind="heading-xs">
        Follow the steps below to upgrade from the Appsmith Community Edition:
      </Text>
      <GuideWrapper>
        <Step count={1}>
          <Text>Connect to the shell of a running container.</Text>
          <CommandBox>
            kubectl exec -it {"<pod>"} <RedStyling>bash</RedStyling>
          </CommandBox>
        </Step>

        <Step count={2}>
          <Text>
            Retrieve the salt and password and add these to values.yaml file of
            the appsmith-ee helm deployment
          </Text>
          <CommandBox>
            <RedStyling>cat</RedStyling>{" "}
            /appsmith-stacks/configuration/docker.env |{" "}
            <RedStyling>grep</RedStyling> APPSMITH_ENCRYPTION_
          </CommandBox>
        </Step>

        <Step count={3}>
          <Text>
            Run the following commands to backup the database which is available
            at <code>/appsmith-stacks/data/backup</code>
          </Text>
          <CommandBox>
            <Comment># Run the backup command</Comment> <br />
            appsmithctl backup <br />
            <br />
            <Comment># Exit the shell from the pod</Comment> <br />
            exit <br />
            <br />
            <Comment># Download the backup file</Comment> <br />
            kubectl <RedStyling>cp</RedStyling>{" "}
            {"<namespace>/appsmith-0:<backup_path> ./<name_of_backup_file>"}
          </CommandBox>
        </Step>

        <Step count={4}>
          <Text>
            Uninstall the Community Edition Helm chart by running the following
            command:
          </Text>
          <CommandBox>helm uninstall appsmith</CommandBox>
        </Step>

        <Step count={5}>
          <Text>Run the following commands to add new Helm chart</Text>
          <CommandBox>
            helm repo <RedStyling>add</RedStyling> appsmith-ee
            https://helm-ee.appsmith.com <br />
            helm repo update
          </CommandBox>
        </Step>

        <Step count={6}>
          <Text>
            Pull the latest values.yaml for appsmith-ee and update encryption
            values
          </Text>
          <CommandBox>
            <Comment># Pull the latest values.yaml</Comment> <br />
            helm show values appsmith-ee/appsmith {">"} values.yaml
            <br />
            <br />
            <Comment># Update encryption values in values.yaml</Comment> <br />
            <BlueStyling>APPSMITH_ENCRYPTION_PASSWORD</BlueStyling>:{" "}
            <PinkStyling>{'"<PASSWORD>"'}</PinkStyling> <br />
            <BlueStyling>APPSMITH_ENCRYPTION_SALT</BlueStyling>:{" "}
            <PinkStyling>{'"<SALT>"'}</PinkStyling>
          </CommandBox>
        </Step>

        <Step count={7}>
          <Text>Run the following commands to deploy the new Helm chart</Text>
          <CommandBox>
            helm <RedStyling>install</RedStyling> appsmith appsmith-ee/appsmith
            -n {"<namespace>"} -f values.yaml
          </CommandBox>
        </Step>
        <Step count={8}>
          <Text>Download the backup file and restore the data</Text>
          <CommandBox>
            <Comment># Download the backup file</Comment> <br />
            kubectl <RedStyling>cp</RedStyling> ./
            {"<name_of_backup_file> <namespace>/<pod>"}
            :/appsmith-stacks/data/backup/ <br />
            <br />
            <Comment># Restore</Comment>
            <br />
            appsmithctl restore
          </CommandBox>
        </Step>

        <Step count={9}>
          <Text>
            Sign in using the Appsmith administrator account and enter your
            license key to activate the instance.
          </Text>
        </Step>
      </GuideWrapper>
    </>
  );
}
export default KubernetesUpgradeGuide;
