import { AxiosPromise } from "axios";
import API from "./Api";
import {
  SUBSCRIPTIONS_PRORATED_INVOICE,
  SUBSCRIPTIONS_UPCOMING_INVOICE,
  SUBSCRIPTIONS_UPDATE_SEATS,
} from "./constants";

export default class SubscriptionsApi {
  static fetchUpcomingInvoiceSummary(seatCount?: number): AxiosPromise {
    return API.get(SUBSCRIPTIONS_UPCOMING_INVOICE, {
      seatCount: seatCount || "",
    });
  }

  static updateSeats(seatCount: number): AxiosPromise {
    return API.put(SUBSCRIPTIONS_UPDATE_SEATS, {
      seatCount,
    });
  }

  static fetchProratedInvoice(seatCount?: number): AxiosPromise {
    return API.get(SUBSCRIPTIONS_PRORATED_INVOICE, {
      seatCount: seatCount || "",
    });
  }
}
