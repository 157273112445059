import { authenticateUser } from "actions/UserActions";
import API from "api/Api";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import log from "loglevel";
import { all, put, takeLatest } from "redux-saga/effects";
import Analytics from "thirdparty/analytics";
import Intercom from "thirdparty/intercom";
import { ReduxAction } from "types/ReduxTypes";
import { UserDetails } from "types/UserTypes";
import analyticEvents from "constants/AnalyticEvents";
import { toast } from "@appsmith/ads";
import { paymentSuccessRedirect } from "actions/paymentActions";
import Smartlook from "thirdparty/smartlook";

export function* InitializeApp() {
  if (process.env.REACT_APP_PRODUCTION) {
    log.disableAll();
  }

  API.init();
  yield Analytics.init();

  yield put(authenticateUser());

  const queryString = new URLSearchParams(window.location.search);

  /*
   * payment key is present when stripe redirects user
   */
  if (queryString.has("payment")) {
    yield put(paymentSuccessRedirect(queryString.get("payment") === "success"));
  }

  /*
   * message key is present when stripe redirects user and auth0 email verification redirect
   */
  if (queryString.has("message")) {
    const stringMessage = queryString.get("message");
    if (stringMessage) {
      toast.show(stringMessage, {
        kind: queryString.get("type") === "error" ? "error" : "success",
      });
    }

    window.history.replaceState(null, "", window.location.pathname);
  }
}

function* InitiateTrackingAndIntegrations(action: ReduxAction<UserDetails>) {
  const { email, name } = action.payload;

  yield Intercom.init(email, name);
  yield Smartlook.init(email);
  yield Analytics.identifyUser(email, {
    customer_type: "self-serve",
    ...action.payload,
  });

  Analytics.track(analyticEvents.USER_LOGIN_SUCCESS, action.payload);
}

export function* AppSaga() {
  yield all([
    takeLatest(ReduxActionTypes.APP_INIT, InitializeApp),
    takeLatest(
      ReduxActionTypes.UPDATE_USER_DETAILS,
      InitiateTrackingAndIntegrations,
    ),
  ]);
}
