import React from "react";
import {
  Avatar,
  Divider,
  Flex,
  ScrollArea,
  SearchInput,
  Text,
} from "@appsmith/ads";
import {
  createMessage,
  MEMBERS_TEXT,
  PRICING_SUMMARY_PURCHASE_SEATS,
} from "constants/Messages";
import { Member as MemberType } from "types/MembersTypes";
import { Empty } from "components/empty";
import { LoadingMembers, SeatStatusIndicator } from "components";
import { useMembers } from "hooks";
import { useSelector } from "react-redux";
import { getFlowType } from "selectors/LicenseSelectors";
import { FlowType } from "types/LicenseTypes";

const SCROLL_AREA_OPTIONS = {
  overflow: {
    x: "hidden",
    y: "scroll",
  },
} as const;

export const Member = React.memo(({ email, seatAssigned }: MemberType) => {
  return (
    <Flex
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Flex
        alignItems="center"
        flexDirection="row"
        gap="spaces-2"
        width="300px"
      >
        <Avatar label={email} size="md" />
        <Flex flexDirection="column">
          <Text kind="body-m">{email}</Text>
        </Flex>
      </Flex>

      <SeatStatusIndicator className="w-[110px]" seatAssigned={seatAssigned} />
    </Flex>
  );
});

const Members = () => {
  const {
    memberList,
    membersLoading,
    observableItemRef,
    paginatedOccupiedSeats,
    paginatedPurchasedSeats,
    searchTerm,
    setSearchTerm,
    showInfiniteScrollLoading,
    totalNumberOfMembers,
  } = useMembers();
  const flowType = useSelector(getFlowType);

  return (
    <Flex
      flexDirection="column"
      gap="spaces-6"
      maxH="500px"
      overflow="hidden"
      w="100%"
    >
      <Flex
        alignItems="center"
        flexDirection="row"
        h="fit-content"
        justifyContent="space-between"
        w="100%"
      >
        <Flex flexDirection="column" gap="spaces-2">
          <Text kind="heading-s">
            {flowType === FlowType.SEAT_BASED
              ? createMessage(
                  PRICING_SUMMARY_PURCHASE_SEATS.members_heading,
                  totalNumberOfMembers,
                )
              : createMessage(MEMBERS_TEXT)}
          </Text>
          <Text kind="body-m">
            {flowType === FlowType.SEAT_BASED
              ? createMessage(
                  PRICING_SUMMARY_PURCHASE_SEATS.members_description,
                )
              : createMessage(
                  PRICING_SUMMARY_PURCHASE_SEATS.occupied_subtext,
                  paginatedOccupiedSeats,
                  paginatedPurchasedSeats,
                )}
          </Text>
        </Flex>
        <Flex>
          <SearchInput
            className="!w-[200px]"
            onChange={(e) => setSearchTerm(e)}
            placeholder={createMessage(
              PRICING_SUMMARY_PURCHASE_SEATS.members_search_placeholder,
            )}
            value={searchTerm}
          />
        </Flex>
      </Flex>
      <Divider />
      <ScrollArea options={SCROLL_AREA_OPTIONS} size="sm">
        <Flex flexDirection="column" gap="spaces-4" width="100%">
          {membersLoading && <LoadingMembers className="!w-full" />}

          {!membersLoading &&
            memberList.map((member) => (
              <Member key={member.email} {...member} />
            ))}

          {showInfiniteScrollLoading && (
            <div
              className="flex justify-center items-center p-[var(--ads-v2-spaces-6)] !w-full"
              ref={observableItemRef}
            >
              <LoadingMembers />
            </div>
          )}

          {!membersLoading && memberList.length === 0 && (
            <Empty
              className="!w-full"
              image={"https://assets.appsmith.com/Nomember.svg"}
              text={createMessage(
                PRICING_SUMMARY_PURCHASE_SEATS.members_empty_state,
              )}
            />
          )}
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

export { Members };
