import React from "react";
import { ACCOUNT_STATUS_TYPES } from "constants/AccountTypeConstants";
import { createMessage, VALID_UNTIL } from "constants/Messages";
import { useSelector } from "react-redux";
import { getLicenseDetails } from "selectors/LicenseSelectors";
import { getDateString } from "utils/CommonUtils";
import { Text } from "@appsmith/ads";
import { getAccountType } from "selectors/AccountSelectors";

function LicenseValidity() {
  const accountType = useSelector(getAccountType);
  const license = useSelector(getLicenseDetails);

  if (
    accountType === ACCOUNT_STATUS_TYPES.TRIAL_ACTIVE ||
    accountType === ACCOUNT_STATUS_TYPES.PAID_IN_GRACE_PERIOD
  ) {
    return (
      <Text data-testid="license-validity" kind="action-s">
        {createMessage(VALID_UNTIL)}: {getDateString(license?.expiry)}
      </Text>
    );
  } else {
    return null;
  }
}

export default LicenseValidity;
