import React from "react";
import App from "./App";
import { Provider } from "react-redux";
import Store from "./store";
import "./index.css";
import * as ReactDOM from "react-dom";
import { Toast } from "@appsmith/ads";
import "tailwindcss/tailwind.css";

ReactDOM.render(
  <Provider store={Store}>
    <Toast
      autoClose={5000}
      closeButton={false}
      draggable={false}
      hideProgressBar
      pauseOnHover={false}
    />
    <App />
  </Provider>,
  document.getElementById("root"),
);
