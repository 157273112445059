import { AxiosPromise } from "axios";
import { ApiResponse } from "types/ApiTypes";
import { LicenseResponseType } from "types/LicenseTypes";
import API from "./Api";
import { LICENSE } from "./constants";
class LicenseApi {
  static async fetchCurrent(): AxiosPromise<ApiResponse<LicenseResponseType>> {
    return API.get(LICENSE);
  }
  static async startTrial(): AxiosPromise<ApiResponse<LicenseResponseType>> {
    return API.post(LICENSE);
  }
}

export default LicenseApi;
