import React from "react";
import { Flex, Text } from "@appsmith/ads";
import { useSelector } from "react-redux";

import { Card } from "components";
import {
  createMessage,
  INVOICE_CARD_DESCRIPTION,
  INVOICE_CARD_INVOICE_ITEMS,
  INVOICE_CARD_TITLE,
} from "constants/Messages";
import {
  getInvoiceSummaryLineItems,
  getUpcomingInvoiceSummaryPeriodEnd,
  getUpcomingInvoiceSummaryTotal,
} from "selectors/SubscriptionSelectors";
import { getDateRangeString, getDateString } from "utils/CommonUtils";
import { LineItem } from "./LineItem";

const InvoiceCard = () => {
  const periodEnd = useSelector(getUpcomingInvoiceSummaryPeriodEnd);
  const lineItems = useSelector(getInvoiceSummaryLineItems);
  const total = useSelector(getUpcomingInvoiceSummaryTotal);

  return (
    <Card flexDirection="column" gap="spaces-9">
      <Flex flexDirection="column" gap="spaces-3">
        <Text kind="heading-m">{createMessage(INVOICE_CARD_TITLE)}</Text>
        <Text kind="body-m">
          {createMessage(
            INVOICE_CARD_DESCRIPTION,
            getDateString(periodEnd, "MMM Do, YYYY"),
          )}
        </Text>
      </Flex>

      <Flex flexDirection="column" gap="spaces-3">
        {/* Title */}
        <Flex
          alignItems="center"
          borderBottom="1px solid var(--ads-v2-color-border)"
          justifyContent="space-between"
          paddingBottom="spaces-4"
        >
          <Text className="w-[60%]" kind="body-s">
            {createMessage(INVOICE_CARD_INVOICE_ITEMS.description)}
          </Text>
          <Text className="w-[64px] text-center" kind="body-s">
            {createMessage(INVOICE_CARD_INVOICE_ITEMS.quantity)}
          </Text>
          <Text className="w-[64px] text-center" kind="body-s">
            {createMessage(INVOICE_CARD_INVOICE_ITEMS.unit_price)}
          </Text>
          <Text className="w-[64px] text-center" kind="body-s">
            {createMessage(INVOICE_CARD_INVOICE_ITEMS.amount)}
          </Text>
        </Flex>
        {/* Line items */}
        {lineItems.map((item, index) => (
          <LineItem
            amount={item.amount}
            key={index}
            quantity={item.quantity}
            subTitle={getDateRangeString(item.period.start, item.period.end)}
            title={item.description}
            unitPrice={item.unitPrice}
          />
        ))}

        {/* total */}
        <LineItem
          amount={total || 0}
          amountKind="heading-s"
          noBorder
          title={createMessage(
            INVOICE_CARD_INVOICE_ITEMS.total,
            getDateString(periodEnd, "MMM Do, YYYY"),
          )}
          titleKind="heading-s"
        />
      </Flex>
    </Card>
  );
};

export { InvoiceCard };
