import React from "react";
import CommandBox from "../CommandBox";
import { Step } from "../DocumentViewer";
import {
  GuideWrapper,
  RedStyling,
  CyanStyling,
} from "../DocumentViewer.styles";
import { Text } from "@appsmith/ads";
function DockerUpgradeGuide() {
  return (
    <>
      <Text data-testid="docker-upgrade-guide" kind="heading-xs">
        Follow the steps below to upgrade from the Appsmith Community Edition:
      </Text>
      <GuideWrapper>
        <Step count={1}>
          <Text>
            Create a backup of the Appsmith Community Edition. Run the below
            command in the directory with <code>docker-compose.yml</code>. The
            output file is stored in <code>/appsmith-stacks/data/backup/</code>
          </Text>
          <CommandBox>
            <RedStyling>docker-compose</RedStyling> exec appsmith appsmithctl
            backup
          </CommandBox>
        </Step>

        <Step count={2}>
          <Text>
            Open the <code>docker-compose.yml</code> file and look for the{" "}
            <code>image:</code> key. Update the image from{" "}
            <code>appsmith-ce</code> to <code>appsmith-ee</code>.
          </Text>
          <CommandBox>
            <pre>
              <CyanStyling>services</CyanStyling>:
              <br />
              {"  "} <CyanStyling>appsmith</CyanStyling>:
              <br />
              <CyanStyling>image</CyanStyling>:
              index.docker.io/appsmith/appsmith-ee
            </pre>
          </CommandBox>
        </Step>

        <Step count={3}>
          <Text>Recreate the instance by running the following command:</Text>
          <CommandBox>
            <RedStyling>docker-compose</RedStyling> up -d
          </CommandBox>
        </Step>

        <Step count={4}>
          <Text>
            Sign in using the Appsmith administrator account and enter your
            license key to activate the instance.
          </Text>
        </Step>
      </GuideWrapper>
    </>
  );
}
export default DockerUpgradeGuide;
