import React from "react";
import { Card } from "components";
import { Flex } from "@appsmith/ads";
import { AddSeat } from "./AddSeat";
import { Members } from "./Members";

const Content = () => {
  return (
    <Flex flexDirection="column" gap="spaces-4" gridArea="content">
      <Card>
        <AddSeat />
      </Card>
      <Card>
        <Members />
      </Card>
    </Flex>
  );
};

export { Content };
