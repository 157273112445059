import { ReduxActionTypes } from "constants/ReduxActionConstants";
import {
  ProratedInvoiceResponseType,
  UpcomingInvoiceSummaryResponseType,
} from "types/SubscriptionTypes";
import { createAction } from "utils/ActionUtils";

export const fetchUpcomingInvoiceSummary = (val?: number) =>
  createAction(
    ReduxActionTypes.FETCH_SUBSCRIPTIONS_UPCOMING_INVOICE_SUMMARY,
    val,
  );

export const fetchUpcomingInvoiceSummarySuccess = (
  val: UpcomingInvoiceSummaryResponseType,
) =>
  createAction(
    ReduxActionTypes.FETCH_SUBSCRIPTIONS_UPCOMING_INVOICE_SUMMARY_SUCCESS,
    val,
  );

export const setUpcomingInvoiceLoading = (val: boolean) =>
  createAction(
    ReduxActionTypes.SET_SUBSCRIPTIONS_UPCOMING_INVOICE_LOADING,
    val,
  );

export const setSeats = (val: number) =>
  createAction(ReduxActionTypes.SET_SUBSCRIPTIONS_SEATS, val);

export const updateSeats = (val: number) =>
  createAction(ReduxActionTypes.UPDATE_SUBSCRIPTIONS_SEATS, val);

export const updateSeatsSuccess = () =>
  createAction(ReduxActionTypes.UPDATE_SUBSCRIPTIONS_SEATS_SUCCESS);

export const updateSeatsFailed = () =>
  createAction(ReduxActionTypes.UPDATE_SUBSCRIPTIONS_SEATS_FAILED);

export const fetchProratedInvoice = (seatCount?: number) =>
  createAction(
    ReduxActionTypes.FETCH_SUBSCRIPTIONS_PRORATED_INVOICE,
    seatCount,
  );

export const fetchProratedInvoiceSuccess = (val: ProratedInvoiceResponseType) =>
  createAction(
    ReduxActionTypes.FETCH_SUBSCRIPTIONS_PRORATED_INVOICE_SUCCESS,
    val,
  );

export const fetchProratedInvoiceFailed = () =>
  createAction(ReduxActionTypes.FETCH_SUBSCRIPTIONS_PRORATED_INVOICE_FAILED);
