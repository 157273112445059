import React, { PropsWithChildren } from "react";
import { Grid } from "@appsmith/ads";

const Container = (props: PropsWithChildren) => {
  return (
    <Grid
      columnGap="spaces-4"
      gridTemplateAreas="'back back' 'header header' 'content summary'"
      gridTemplateColumns="1fr 400px"
      rowGap="spaces-7"
    >
      {props.children}
    </Grid>
  );
};

export { Container };
