import React from "react";
import { Flex, Text } from "@appsmith/ads";

interface HeaderProps {
  heading: string;
  subHeading?: string;
}

const Header: React.FC<HeaderProps> = ({ heading, subHeading }) => {
  return (
    <Flex flexDirection={"column"} gridArea={"header"}>
      <Text className="mb-2 !font-bold" kind="heading-l">
        {heading}
      </Text>
      {subHeading && <Text renderAs="p">{subHeading}</Text>}
    </Flex>
  );
};

export { Header };
