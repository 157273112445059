import React from "react";
import { Navigate } from "react-router-dom";
import PlansInfo from "features/plans-info/PlansInfo";
import { PlansContainer } from "./Plans.styles";
import { PageContainer } from "global-styles/global.styles";
import { useSelector } from "react-redux";
import { shouldShowPlans } from "selectors/LicenseSelectors";
import { withAuthentication, withEmailVerification } from "utils/RouterUtils";
import { createMessage, PLANS_TEXT } from "constants/Messages";
import { Text } from "@appsmith/ads";
import { LICENSE } from "constants/RouteContants";

function Plans() {
  const showPlansPage = useSelector(shouldShowPlans);

  if (showPlansPage) {
    return (
      <PageContainer>
        <Text className="!font-semibold" kind="heading-l">
          {createMessage(PLANS_TEXT)}
        </Text>
        <PlansContainer>
          <PlansInfo />
        </PlansContainer>
      </PageContainer>
    );
  } else {
    return <Navigate to={LICENSE} />;
  }
}

export default withAuthentication(withEmailVerification(Plans));
