import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Layout from "features/layout/Layout";

import Billing from "pages/billing/Billing";
import License from "pages/license/License";
import Plans from "pages/plans/Plans";
import VerifyEmail from "pages/verify-email";
import PricingSummary from "pages/pricing-summary";
import Members from "pages/members";
import UpgradePlan from "pages/upgrade-plan";

import {
  PLANS,
  LICENSE,
  BILLING,
  VERIFY_EMAIL,
  PRICING_SUMMARY,
  MEMBERS,
  UPGRADE_PLAN,
} from "constants/RouteContants";

function AppRouter() {
  return (
    <Routes>
      <Route element={<Layout />} path="/">
        <Route element={<Navigate to={LICENSE} />} path="/" />
        <Route element={<License />} path={LICENSE} />
        <Route element={<Plans />} path={PLANS} />
        <Route element={<Billing />} path={BILLING} />
        <Route element={<VerifyEmail />} path={VERIFY_EMAIL} />
        <Route element={<PricingSummary />} path={PRICING_SUMMARY} />
        <Route element={<Members />} path={MEMBERS} />
        <Route element={<UpgradePlan />} path={UPGRADE_PLAN} />
        {/* Catch-all route for any other paths */}
        <Route element={<Navigate to={LICENSE} />} path="*" />
      </Route>
    </Routes>
  );
}
export default AppRouter;
