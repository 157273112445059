import React from "react";
import { Flex, FlexProps } from "@appsmith/ads";

function Card(props: FlexProps) {
  return (
    <Flex
      {...props}
      bg="var(--ads-v2-color-bg)"
      border="1px solid var(--ads-v2-color-border)"
      borderRadius="var(--ads-v2-border-radius)"
      p="spaces-7"
    />
  );
}

export { Card };
