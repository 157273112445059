import styled from "styled-components";
import { Banner } from "@appsmith/ads";

export const StyledBanner = styled(Banner)`
  animation: fadeIn ease-in 0.2s;
  border-bottom: none !important;
  span.link {
    cursor: pointer;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
