import { all, call, delay, put, takeLatest } from "redux-saga/effects";
import { Auth0Client, createAuth0Client, IdToken } from "@auth0/auth0-spa-js";
import {
  authenticateUserSuccess,
  storeAccessToken,
  updateUserDetails,
} from "actions/UserActions";
import { UserDetails } from "types/UserTypes";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { isAuthenticationRedirectUrl } from "utils/UserUtils";
import log from "loglevel";
import Analytics from "thirdparty/analytics";
import analyticEvents from "constants/AnalyticEvents";
import { toast } from "@appsmith/ads";
import {
  ACCESS_TOKEN_ERROR,
  createMessage,
  GENERIC_ERROR_MESSAGE,
} from "constants/Messages";
import { fetchAccountDetails } from "actions/AccountActions";
import { fetchSeatBasedPricingEnvData } from "actions/EnvironmentActions";

let auth0Client: Auth0Client;

export function* auth0() {
  let auth0Domain = process.env.REACT_APP_AUTHO_DOMAIN || "";

  if (auth0Domain?.endsWith("/")) {
    auth0Domain = auth0Domain.slice(0, -1);
  }
  const urlParams = new URLSearchParams(window.location.search);
  const emailParam = urlParams.get("email");

  auth0Client = yield call(createAuth0Client, {
    domain: auth0Domain,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
    authorizationParams: {
      redirect_uri: window.location.origin + window.location.pathname,
      screen_hint: "signup",
    },
  });

  const { isAuth0FailureRedirection, isAuth0SuccessRedirection } = yield call(
    isAuthenticationRedirectUrl,
  );

  if (isAuth0SuccessRedirection) {
    yield call([auth0Client, auth0Client.handleRedirectCallback]);
    window.history.replaceState(null, "", window.location.pathname);
  } else if (isAuth0FailureRedirection) {
    const queryParams = new URLSearchParams(window.location.search);

    log.error(queryParams.get("error_description"));
    const errorDescription = queryParams.get("error_description");
    if (errorDescription) {
      toast.show(errorDescription, {
        kind: "error",
      });
    }
    yield delay(2000);
    yield call(logoutUser);
    return;
  }

  const response: {
    isAuthenticated: boolean;
    isLoading: boolean;
    token?: IdToken;
    user?: UserDetails;
  } = {
    isAuthenticated: yield call([auth0Client, auth0Client.isAuthenticated]),
    isLoading: false,
  };

  if (response.isAuthenticated) {
    response.token = yield call([auth0Client, auth0Client.getIdTokenClaims]);
    response.user = yield call([auth0Client, auth0Client.getUser]);
  } else {
    Analytics.track(analyticEvents.USER_LOGIN_INIT);
    response.isLoading = true;
    yield call([auth0Client, auth0Client.loginWithRedirect], {
      fragment: emailParam ? `email=${emailParam}` : "",
    });
  }

  return response;
}

export function* authenticateUser() {
  const { isAuthenticated, isLoading, token, user } = yield call(auth0);

  try {
    if (isAuthenticated) {
      if (token && token["__raw"]) {
        yield put(storeAccessToken(token["__raw"]));
        yield put(
          updateUserDetails({
            email: user.email,
            email_verified: user.email_verified,
            name: user.name,
          }),
        );
        yield put(fetchSeatBasedPricingEnvData());
        if (user.email_verified) {
          yield put(fetchAccountDetails());
        } else {
          yield put(authenticateUserSuccess());
        }
      } else {
        throw new Error(createMessage(ACCESS_TOKEN_ERROR));
      }
    } else if (!isLoading) {
      throw Error();
    }
  } catch (e) {
    log.error(e);

    toast.show(createMessage(GENERIC_ERROR_MESSAGE), {
      kind: "error",
    });

    yield delay(2000);
    yield call(logoutUser);
  }
}

export function* logoutUser() {
  auth0Client.logout();
}

export function* UserSaga() {
  yield all([
    takeLatest(ReduxActionTypes.AUTHENTICATE_USER, authenticateUser),
    takeLatest(ReduxActionTypes.LOGOUT_USER, logoutUser),
  ]);
}
