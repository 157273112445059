import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { SeatBasedPricingEnvDataResponseType } from "types/EnvironmentTypes";
import { createAction } from "utils/ActionUtils";

export const fetchSeatBasedPricingEnvData = () =>
  createAction(ReduxActionTypes.FETCH_SEAT_BASED_PRICING_ENV_DATA);

export const setSeatBasedPricingEnvData = (
  data: SeatBasedPricingEnvDataResponseType,
) => createAction(ReduxActionTypes.SET_SEAT_BASED_PRICING_ENV_DATA, data);
