import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

import {
  getMembersList,
  getTotalNumberOfMembers,
  getOccupiedSeatCount,
  getMembersLoading,
  getPurchasedSeatCount,
  getPaginatedTotalMembers,
  getPaginatedOccupiedSeats,
  getPaginatedPurchasedSeats,
} from "selectors/MembersSelectors";
import {
  fetchMembers,
  inviteMembers,
  setSeatAssignLoading,
  setSeatUnassignLoading,
  updateMembers,
} from "actions/MembersActions";
import { UpdateMember } from "types/MembersTypes";

export const useMembers = () => {
  const dispatch = useDispatch();
  const memberList = useSelector(getMembersList);
  const totalNumberOfMembers = useSelector(getTotalNumberOfMembers) || 0;
  const occupiedSeatCount = useSelector(getOccupiedSeatCount) || 0;
  const membersLoading = useSelector(getMembersLoading);
  const purchasedSeatCount = useSelector(getPurchasedSeatCount);
  const paginatedTotalMembers = useSelector(getPaginatedTotalMembers) || 0;
  const paginatedOccupiedSeats = useSelector(getPaginatedOccupiedSeats) || 0;
  const paginatedPurchasedSeats = useSelector(getPaginatedPurchasedSeats) || 0;

  const [searchTerm, setSearchTerm] = useState<string>("");

  const observableItemRef = useRef<HTMLDivElement>(null);
  const pageRef = useRef<number>(0);
  const observerRef = useRef<IntersectionObserver | null>(null);

  // debounce the fetchMembers action
  const debouncedFetchMembers = useMemo(
    () =>
      debounce((term: string) => {
        dispatch(fetchMembers({ email: term }));
      }, 500),
    [dispatch],
  );

  // fetch members when search term changes
  useEffect(() => {
    pageRef.current = 0;
    debouncedFetchMembers(searchTerm);

    return () => {
      debouncedFetchMembers.cancel();
    };
  }, [searchTerm, debouncedFetchMembers]);

  // observer for infinite scroll
  useEffect(() => {
    const element = observableItemRef.current;

    if (
      !element ||
      memberList.length === 0 ||
      paginatedTotalMembers <= memberList.length ||
      membersLoading
    )
      return;

    // Cleanup previous observer
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        const newPage = pageRef.current + 1;
        pageRef.current = newPage;
        dispatch(fetchMembers({ page: newPage, email: searchTerm }));
      }
    });

    observerRef.current.observe(element);

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [
    dispatch,
    memberList.length,
    membersLoading,
    searchTerm,
    paginatedTotalMembers,
  ]);

  // Check if all seats are utilized
  const isSeatsFullyUtilized = useMemo(
    () => occupiedSeatCount === purchasedSeatCount,
    [occupiedSeatCount, purchasedSeatCount],
  );

  // show infinte scroll loading when there are more members to fetch
  const showInfiniteScrollLoading = useMemo(
    () =>
      memberList.length > 0 &&
      paginatedTotalMembers > memberList.length &&
      !membersLoading &&
      paginatedTotalMembers > 15,
    [memberList.length, paginatedTotalMembers, membersLoading],
  );

  // Assign seats to members
  const assignSeatsToMembers = useCallback(
    (membersToAssign: UpdateMember[]) => {
      if (isSeatsFullyUtilized) {
        return;
      }

      pageRef.current = 0;
      dispatch(setSeatAssignLoading(true));
      dispatch(
        updateMembers({
          members: membersToAssign,
          page: pageRef.current,
          email: searchTerm,
        }),
      );
    },
    [dispatch, isSeatsFullyUtilized, searchTerm],
  );

  // Unassign seats to members
  const unassignSeatsToMembers = useCallback(
    (membersToUnassign: UpdateMember[]) => {
      pageRef.current = 0;
      dispatch(setSeatUnassignLoading(true));
      dispatch(
        updateMembers({
          members: membersToUnassign,
          page: pageRef.current,
          email: searchTerm,
        }),
      );
    },
    [dispatch, searchTerm],
  );

  // Invite members
  const onInviteMembers = useCallback(
    (emails: string[]) => {
      dispatch(
        inviteMembers({
          emails,
          page: pageRef.current,
          email: searchTerm,
        }),
      );
    },
    [dispatch, searchTerm],
  );

  return {
    memberList,
    membersLoading,
    occupiedSeatCount,
    purchasedSeatCount,
    searchTerm,
    setSearchTerm,
    totalNumberOfMembers,
    observableItemRef,
    isSeatsFullyUtilized,
    assignSeatsToMembers,
    unassignSeatsToMembers,
    onInviteMembers,
    showInfiniteScrollLoading,
    paginatedTotalMembers,
    paginatedOccupiedSeats,
    paginatedPurchasedSeats,
  };
};
