import React from "react";
import { Flex, Text } from "@appsmith/ads";

type EmptyProps = {
  image: string;
  text: string;
  className?: string;
};

const Empty = ({ className, image, text }: EmptyProps) => {
  return (
    <Flex
      alignItems="center"
      className={className}
      flexDirection="column"
      gap="spaces-6"
      h="100%"
      justifyContent="center"
      w="100%"
    >
      <Flex h="127px" w="192px">
        <img alt="empty" src={image} />
      </Flex>
      <Text kind="body-m">{text}</Text>
    </Flex>
  );
};

export { Empty };
