import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { ReduxAction } from "types/ReduxTypes";
import {
  ProratedInvoiceResponseType,
  UpcomingInvoiceSummaryResponseType,
} from "types/SubscriptionTypes";
import { createReducer } from "utils/ReducerUtils";

export type SubscriptionState = {
  upcomingInvoiceSummary: UpcomingInvoiceSummaryResponseType;
  isUpcomingInvoiceSummaryLoading: boolean;
  seats: number;
  isUpdateSeatsLoading: boolean;
  proratedInvoice: ProratedInvoiceResponseType;
  isProratedInvoiceLoading: boolean;
};

const initialState: SubscriptionState = {
  upcomingInvoiceSummary: {
    lineItems: [],
    subtotal: 0,
    totalExcludingTax: 0,
    tax: 0,
    total: undefined,
    amountDue: 0,
    periodStart: undefined,
    periodEnd: undefined,
  },
  isUpcomingInvoiceSummaryLoading: true,
  seats: 0,
  isUpdateSeatsLoading: false,
  proratedInvoice: {
    lineItems: [],
  },
  isProratedInvoiceLoading: true,
};

export const subscriptionReducer = createReducer(initialState, {
  [ReduxActionTypes.SET_SUBSCRIPTIONS_UPCOMING_INVOICE_LOADING]: (
    state: SubscriptionState,
    action: ReduxAction<boolean>,
  ) => {
    return {
      ...state,
      isUpcomingInvoiceSummaryLoading: action.payload,
      isProratedInvoiceLoading: false,
    };
  },
  [ReduxActionTypes.FETCH_SUBSCRIPTIONS_UPCOMING_INVOICE_SUMMARY]: (
    state: SubscriptionState,
  ) => {
    return {
      ...state,
    };
  },
  [ReduxActionTypes.FETCH_SUBSCRIPTIONS_UPCOMING_INVOICE_SUMMARY_SUCCESS]: (
    state: SubscriptionState,
    action,
  ) => {
    return {
      ...state,
      upcomingInvoiceSummary: action.payload,
      isUpcomingInvoiceSummaryLoading: false,
      isProratedInvoiceLoading: false,
    };
  },
  [ReduxActionTypes.SET_SUBSCRIPTIONS_SEATS]: (
    state: SubscriptionState,
    action: ReduxAction<number>,
  ) => {
    return {
      ...state,
      seats: action.payload,
    };
  },
  [ReduxActionTypes.UPDATE_SUBSCRIPTIONS_SEATS]: (state: SubscriptionState) => {
    return {
      ...state,
      isUpdateSeatsLoading: true,
    };
  },
  [ReduxActionTypes.UPDATE_SUBSCRIPTIONS_SEATS_SUCCESS]: (
    state: SubscriptionState,
  ) => {
    return {
      ...state,
      isUpdateSeatsLoading: false,
    };
  },
  [ReduxActionTypes.UPDATE_SUBSCRIPTIONS_SEATS_FAILED]: (
    state: SubscriptionState,
  ) => {
    return {
      ...state,
      isUpdateSeatsLoading: false,
    };
  },
  [ReduxActionTypes.FETCH_SUBSCRIPTIONS_PRORATED_INVOICE]: (
    state: SubscriptionState,
  ) => {
    return {
      ...state,
      isProratedInvoiceLoading: true,
    };
  },
  [ReduxActionTypes.FETCH_SUBSCRIPTIONS_PRORATED_INVOICE_SUCCESS]: (
    state: SubscriptionState,
    action: ReduxAction<ProratedInvoiceResponseType>,
  ) => {
    return {
      ...state,
      proratedInvoice: action.payload,
      isProratedInvoiceLoading: false,
      isUpcomingInvoiceSummaryLoading: false,
    };
  },
  [ReduxActionTypes.FETCH_SUBSCRIPTIONS_PRORATED_INVOICE_FAILED]: (
    state: SubscriptionState,
  ) => {
    return {
      ...state,
      isProratedInvoiceLoading: false,
      isUpcomingInvoiceSummaryLoading: false,
    };
  },
});
