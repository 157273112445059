import styled from "styled-components";
import { Text } from "@appsmith/ads";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const LicenseKeyHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LicenseKeyValue = styled(Text)`
  max-width: 950px;
  word-wrap: break-word;
`;
