import { combineReducers } from "redux";

import { LicenseStateType, licenseReducer } from "./LicenseReducer";
import { UserStateType, userReducer } from "./UserReducer";
import { UsageState, UsageReducer } from "./UsageReducer";
import { PaymentsState, paymentsReducer } from "./PaymentsReducer";
import { AccountStateType, accountReducer } from "./AccountReducer";
import { MembersStateType, membersReducer } from "./MembersReducer";
import { SubscriptionState, subscriptionReducer } from "./SubscriptionsReducer";
import { environmentReducer, EnvironmentStateType } from "./EnvironmentReducer";

export interface AppState {
  account: AccountStateType;
  license: LicenseStateType;
  user: UserStateType;
  usage: UsageState;
  payments: PaymentsState;
  subscription: SubscriptionState;
  members: MembersStateType;
  environment: EnvironmentStateType;
}

export const rootReducer = combineReducers({
  account: accountReducer,
  license: licenseReducer,
  user: userReducer,
  usage: UsageReducer,
  payments: paymentsReducer,
  subscription: subscriptionReducer,
  members: membersReducer,
  environment: environmentReducer,
});
