import { ACCOUNT_STATUS_TYPES } from "constants/AccountTypeConstants";
import {
  LICENSE_ORIGIN,
  LICENSE_PLAN,
  PRODUCT_EDITION,
} from "constants/LicenseConstants";

export enum BillingModel {
  SEAT_BASED = "SEAT_BASED",
}

export type LicenseResponseType = {
  licenseKey: string;
  licenseType: string;
  licenseStatus: string;
  expiry: number;
  createdAt: number;
  stripeCustomerId?: string;
  licenseId: string;
  stripeSubscriptionId: string;
  licenseAccountStatus: ACCOUNT_STATUS_TYPES;
  licenseOrigin: LICENSE_ORIGIN;
  licensePlan: LICENSE_PLAN;
  productEdition: PRODUCT_EDITION;
  metadata?: {
    notes: string;
    salesforceOpportunityLink: string;
    primaryContactEmail: string;
    primaryContactName: string;
    createdBy: string;
    stripeStatus: string;
  };
  billingModel?: BillingModel | string;
};

export type RemainingTime = {
  count: number;
  suffix: string;
};

export enum FlowType {
  SEAT_BASED = "SEAT_BASED",
  USAGE_BASED = "USAGE_BASED",
  SEAT_BASED_UPDATE = "SEAT_BASED_UPDATE",
}
