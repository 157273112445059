import React from "react";
import { Button, Checkbox, Divider, Flex, Text, Tooltip } from "@appsmith/ads";
import {
  ALL_SEATS_ASSIGNED_TOOLTIP,
  ASSIGN_SEATS_CTA,
  createMessage,
  SELECTED_SEATS_TEXT,
  UNASSIGN_SEATS_CTA,
} from "constants/Messages";
import { useSelector } from "react-redux";
import {
  getAssignSeatsLoading,
  getUnassignSeatsLoading,
} from "selectors/MembersSelectors";

interface TableHeaderProps {
  selectedCount: number;
  onBulkSelect: (checked: boolean) => void;
  isAllSelected: boolean;
  isIndeterminate: boolean;
  handleAssignSeats: () => void;
  handleUnassignSeats: () => void;
  isSeatsFullyUtilized: boolean;
  isUnassignDisabled: boolean;
  isAssignDisabled: boolean;
}

const TableBulkSelectHeader = ({
  handleAssignSeats,
  handleUnassignSeats,
  isAllSelected,
  isAssignDisabled,
  isIndeterminate,
  isSeatsFullyUtilized,
  isUnassignDisabled,
  onBulkSelect,
  selectedCount,
}: TableHeaderProps) => {
  const assignSeatsLoading = useSelector(getAssignSeatsLoading);
  const unassignSeatsLoading = useSelector(getUnassignSeatsLoading);
  const showActions = selectedCount > 0;

  if (!showActions) {
    return null;
  }

  return (
    <Flex
      alignItems="center"
      bg="var(--ads-v2-colors-content-surface-neutral-bg)"
      className="mt-[-48px]"
      height="var(--ads-v2-spaces-13)"
      justifyContent="space-between"
      left={0}
      padding="spaces-5"
      position={"sticky"}
      top={0}
      width="100%"
      zIndex={3}
    >
      <Flex alignItems="center">
        <Flex height="16px" width="56px">
          <Checkbox
            isIndeterminate={isIndeterminate}
            isSelected={isAllSelected}
            onChange={onBulkSelect}
          />
        </Flex>
        <Flex alignItems="center" gap="spaces-3">
          <Text kind="body-m">
            {createMessage(SELECTED_SEATS_TEXT, selectedCount)}
          </Text>
          <Divider className="!h-[20px]" orientation="vertical" />
          <Tooltip
            content={createMessage(ALL_SEATS_ASSIGNED_TOOLTIP)}
            isDisabled={!isSeatsFullyUtilized}
            placement="top"
          >
            <Flex>
              <Button
                isDisabled={isAssignDisabled}
                isLoading={assignSeatsLoading}
                kind="primary"
                onClick={handleAssignSeats}
                size="sm"
              >
                {createMessage(ASSIGN_SEATS_CTA)}
              </Button>
            </Flex>
          </Tooltip>
          <Button
            isDisabled={isUnassignDisabled}
            isLoading={unassignSeatsLoading}
            kind="secondary"
            onClick={handleUnassignSeats}
            size="sm"
          >
            {createMessage(UNASSIGN_SEATS_CTA)}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export { TableBulkSelectHeader };
