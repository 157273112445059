import React from "react";
import { Button, Flex } from "@appsmith/ads";
import {
  createMessage,
  PRICING_SUMMARY_PURCHASE_SEATS,
} from "constants/Messages";

const Back = () => {
  const handleBack = () => {
    window.history.back();
  };

  return (
    <Flex gridArea={"back"}>
      <Button
        kind="tertiary"
        onClick={handleBack}
        size="sm"
        startIcon="arrow-left-line"
      >
        {createMessage(PRICING_SUMMARY_PURCHASE_SEATS.back_text)}
      </Button>
    </Flex>
  );
};

export { Back };
