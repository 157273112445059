export const MEMBERS = "/members";
export const INVITE_MEMBERS = `${MEMBERS}/invite`;
export const MEMBERS_COUNT = `${MEMBERS}/count`;

export const LICENSE = "/license";

export const PAYMENTS = "/payment";
export const PAYMENTS_CHECKOUT = `${PAYMENTS}/checkout`;
export const PAYMENTS_DASHBOARD = `${PAYMENTS}/dashboard`;

export const SUBSCRIPTIONS = "/subscription";
export const SUBSCRIPTIONS_UPCOMING_INVOICE = `${SUBSCRIPTIONS}/upcoming-invoice`;
export const SUBSCRIPTIONS_UPDATE_SEATS = `${SUBSCRIPTIONS}/seats`;
export const SUBSCRIPTIONS_PRORATED_INVOICE = `${SUBSCRIPTIONS}/prorated-items`;

export const USAGE = "/usage";
export const USAGE_STATS = `${USAGE}/stats`;

export const ACCOUNT = "/account";

export const ENVIRONMENT = "/environment";
export const ENVIRONMENT_SEAT_BASED_PRICING = `${ENVIRONMENT}/seat-based-pricing`;
