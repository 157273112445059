import { AXIOS_CONNECTION_ABORT_CODE } from "constants/ApiConstants";
import { API_ERROR_MESSAGES } from "constants/CommonConstant";
import { call } from "redux-saga/effects";
import { ApiResponse } from "types/ApiTypes";
import { logoutUser } from "./UserSaga";
import { toast, ToastKind } from "@appsmith/ads";

export function* isValidResponse(response: ApiResponse<unknown>) {
  if (!response) {
    throw Error("Response object is missing");
  } else if (response?.code === AXIOS_CONNECTION_ABORT_CODE) {
    throw Error("");
  } else if (
    response.responseMeta.status === 200 ||
    response.responseMeta.status === 201 ||
    response.responseMeta.status === 202
  ) {
    return true;
  } else if (response.responseMeta.status === 401) {
    yield call(logoutUser);
  } else {
    throw Error(API_ERROR_MESSAGES[404]);
  }
}

export const showToaster = (message: string, kind: ToastKind = "error") => {
  toast.show(message, {
    kind,
  });
};

export const getErrorMessage = (error: any) => {
  let errorMessage = error.message;
  if (error?.responseMeta?.error?.message) {
    errorMessage = error.responseMeta.error.message;
  }
  return errorMessage;
};
