import React, { useState } from "react";
import {
  ModalBody,
  ModalContent,
  ModalHeader,
  Modal,
  ModalTrigger,
  Button,
  Input,
  Flex,
  Tag,
  Spinner,
  Divider,
} from "@appsmith/ads";
import { useDispatch, useSelector } from "react-redux";

import {
  createMessage,
  INVITE_MEMBERS_CTA,
  INVITE_MEMBERS_MODAL_BUTTON_TEXT,
  INVITE_MEMBERS_MODAL_HEADER,
  INVITE_MEMBERS_MODAL_TEXTBOX_PLACEHOLDER,
} from "constants/Messages";
import {
  getInviteModalLoading,
  getInviteUsersStatus,
} from "selectors/MembersSelectors";
import { inviteMembersStatusReset } from "actions/MembersActions";
import { Member } from "pages/pricing-summary/components/Members";

const InviteModal = ({
  onInviteMembers,
}: {
  onInviteMembers: (emails: string[]) => void;
}) => {
  const dispatch = useDispatch();
  const [emails, setEmails] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const inviteModalLoading = useSelector(getInviteModalLoading);
  const inviteUsersStatus = useSelector(getInviteUsersStatus);

  const handleInputChange = (value: string) => {
    // Handle both comma-separated and single inputs
    const delimiter = value[value.length - 1];
    const shouldProcessInput =
      [" ", ",", "\n"].includes(delimiter) || value.includes(",");

    if (!shouldProcessInput) {
      setInputValue(value);
      return;
    }

    // Process all potential email addresses
    const newEmails = new Set(
      value
        .split(/[,\s\n]+/)
        .map((email) => email.trim())
        .filter((email) => email && !emails.includes(email)),
    );

    if (newEmails.size > 0) {
      setEmails([...emails, ...newEmails]);
    }
    setInputValue("");
  };

  const removeEmail = (emailToRemove: string) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  const handleInvite = () => {
    onInviteMembers(emails);
    setEmails([]);
    setInputValue("");
  };

  const onCloseModalHandler = (open: boolean) => {
    if (!open) {
      setEmails([]);
      setInputValue("");
      dispatch(inviteMembersStatusReset());
    }
  };

  return (
    <Modal onOpenChange={onCloseModalHandler}>
      <ModalTrigger>
        <Button kind="secondary" size="md">
          {createMessage(INVITE_MEMBERS_CTA)}
        </Button>
      </ModalTrigger>
      <ModalContent className="!w-[560px]">
        <ModalHeader>{createMessage(INVITE_MEMBERS_MODAL_HEADER)}</ModalHeader>
        <ModalBody>
          {inviteModalLoading ? (
            <Flex alignItems="center" height="200px" justifyContent="center">
              <Spinner size="md" />
            </Flex>
          ) : (
            <Flex alignItems="flex-end" flexDirection="column" gap="spaces-4">
              <Flex className="w-full" flexDirection="column">
                <Flex className="mb-2" flexWrap="wrap" gap="spaces-1">
                  {emails.map((email) => (
                    <Tag
                      key={email}
                      kind="neutral"
                      onClose={() => removeEmail(email)}
                    >
                      {email}
                    </Tag>
                  ))}
                </Flex>
                <Input
                  onChange={handleInputChange}
                  placeholder={createMessage(
                    INVITE_MEMBERS_MODAL_TEXTBOX_PLACEHOLDER,
                  )}
                  renderAs="textarea"
                  size="md"
                  value={inputValue}
                />
              </Flex>
              {inviteUsersStatus.length > 0 && (
                <Flex
                  flexDirection="column"
                  gap="spaces-4"
                  maxHeight="200px"
                  overflowY="auto"
                  paddingTop="spaces-4"
                  width="100%"
                >
                  {inviteUsersStatus.map((user) => (
                    <Flex
                      flexDirection="column"
                      gap="spaces-2"
                      key={user.email}
                    >
                      <Member {...user} />
                      <Divider />
                    </Flex>
                  ))}
                </Flex>
              )}
              <Button
                className="w-fit"
                isDisabled={emails.length === 0}
                kind="primary"
                onClick={handleInvite}
                size="md"
              >
                {createMessage(INVITE_MEMBERS_MODAL_BUTTON_TEXT)}
              </Button>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { InviteModal };
