import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useSearchParams } from "react-router-dom";

import { PageContainer } from "global-styles/global.styles";
import { shouldShowMembers } from "selectors/LicenseSelectors";
import { withAuthentication, withEmailVerification } from "utils/RouterUtils";
import { MembersList, SeatsCard } from "./components";
import { Flex, toast } from "@appsmith/ads";
import { LICENSE } from "constants/RouteContants";
import { SUBSCRIPTIONS_UPDATE_SEATS_SUCCESS } from "constants/Messages";
import { createMessage } from "constants/Messages";
import { fetchMembersCount } from "actions/MembersActions";

function Members() {
  const dispatch = useDispatch();
  const showMembers = useSelector(shouldShowMembers);
  const [searchParams, setSearchParams] = useSearchParams();
  const updateSeatsSuccess = searchParams.get("updateSeats") === "success";

  useEffect(
    function updateSeatsSuccessToast() {
      if (updateSeatsSuccess) {
        toast.show(createMessage(SUBSCRIPTIONS_UPDATE_SEATS_SUCCESS), {
          kind: "success",
        });
        searchParams.delete("updateSeats");
        setSearchParams(searchParams);
      }
    },
    [updateSeatsSuccess],
  );

  useEffect(function getTotalMembersCount() {
    dispatch(fetchMembersCount());
  }, []);

  if (!showMembers) {
    return <Navigate to={LICENSE} />;
  }

  return (
    // 50px is topbar height
    <PageContainer className="h-[calc(100vh-50px)] overflow-hidden">
      <Flex flexDirection="column" gap="spaces-7" h="100%" overflow="hidden">
        <SeatsCard />
        <MembersList />
      </Flex>
    </PageContainer>
  );
}

export default withAuthentication(withEmailVerification(Members));
