import React, { useEffect } from "react";
import { Flex, Link, Spinner, Text } from "@appsmith/ads";
import { PlanCard } from "./components";
import { InvoiceCard } from "./components";
import { getUpcomingInvoiceSummaryIsLoading } from "selectors/SubscriptionSelectors";
import { useDispatch, useSelector } from "react-redux";
import { fetchUpcomingInvoiceSummary } from "actions/SubscriptionActions";
import { createMessage, SUPPORT_EMAIL, REACH_OUT } from "constants/Messages";
import { APPSMITH_SALES_EMAIL } from "constants/CommonConstant";

const SeatBased = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getUpcomingInvoiceSummaryIsLoading);
  const mailTo = `mailto:${APPSMITH_SALES_EMAIL}`;

  useEffect(() => {
    dispatch(fetchUpcomingInvoiceSummary());
  }, []);

  return (
    <Flex flexDirection="column" gap="spaces-7">
      {isLoading ? (
        <Flex alignItems="center" height="70vh" justifyContent="center">
          <Spinner size="lg" />
        </Flex>
      ) : (
        <>
          <PlanCard />
          <InvoiceCard />
          <Flex gap="spaces-2">
            <Text renderAs="span">{createMessage(REACH_OUT)} </Text>

            <Link kind="secondary" to={mailTo}>
              {createMessage(SUPPORT_EMAIL)}
            </Link>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export { SeatBased };
