import { AppState } from "reducers";
import { MembersStateType } from "reducers/MembersReducer";
import { createSelector } from "reselect";

export const getMembers = (state: AppState): MembersStateType => state.members;

export const getMembersLoading = createSelector(
  [getMembers],
  (membersSlice) => membersSlice.isLoading,
);

export const getMembersError = createSelector(
  [getMembers],
  (membersSlice) => membersSlice.error,
);

export const getMembersList = createSelector(
  [getMembers],
  (membersSlice) => membersSlice.members.members || [],
);

export const getTotalNumberOfMembers = createSelector(
  [getMembers],
  (membersSlice) => membersSlice.totalMembers || null, // null is used to indicate that the value is not yet available
);

export const getPurchasedSeatCount = createSelector(
  [getMembers],
  (membersSlice) => membersSlice.purchasedSeats,
);

export const getOccupiedSeatCount = createSelector(
  [getMembers],
  (membersSlice) => membersSlice.occupiedSeats,
);

export const getInviteModalLoading = createSelector(
  [getMembers],
  (membersSlice) => membersSlice.inviteModalLoading,
);

export const getAssignSeatsLoading = createSelector(
  [getMembers],
  (membersSlice) => membersSlice.assignSeatsLoading,
);

export const getUnassignSeatsLoading = createSelector(
  [getMembers],
  (membersSlice) => membersSlice.unassignSeatsLoading,
);

export const getPaginatedTotalMembers = createSelector(
  [getMembers],
  (membersSlice) => membersSlice.members.paginatedTotalMembers,
);

export const getPaginatedPurchasedSeats = createSelector(
  [getMembers],
  (membersSlice) => membersSlice.members.paginatedPurchasedSeats,
);

export const getPaginatedOccupiedSeats = createSelector(
  [getMembers],
  (membersSlice) => membersSlice.members.paginatedOccupiedSeats,
);

export const getInviteUsersStatus = createSelector(
  [getMembers],
  (membersSlice) => membersSlice.inviteUsersStatus,
);
