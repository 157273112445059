import React from "react";
import LicenseDetails from "features/license-details/LicenseDetails";
import { createMessage, LICENSE_PAGE_HEADING } from "constants/Messages";
import { PageContainer } from "global-styles/global.styles";
import InstallationAndUpgrade from "features/installation-and-upgrade";
import { withAuthentication, withEmailVerification } from "utils/RouterUtils";
import { Text } from "@appsmith/ads";
import { useSelector } from "react-redux";
import { ACCOUNT_STATUS_TYPES } from "constants/AccountTypeConstants";
import { getAccountType } from "selectors/AccountSelectors";

function License() {
  const accountType = useSelector(getAccountType);

  const canShowLicenseCard = accountType !== ACCOUNT_STATUS_TYPES.FREE;

  return (
    <PageContainer>
      <Text className="!font-semibold !mb-8" kind="heading-l" renderAs="p">
        {createMessage(LICENSE_PAGE_HEADING)}
      </Text>
      {canShowLicenseCard && <LicenseDetails />}
      <InstallationAndUpgrade />
    </PageContainer>
  );
}

export default withAuthentication(withEmailVerification(License));
