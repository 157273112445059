import React from "react";
import { Navigate } from "react-router-dom";
import { createMessage, BILLING_AND_USAGE_HEADING } from "constants/Messages";
import { PageContainer } from "global-styles/global.styles";
import { useSelector } from "react-redux";
import {
  getFlowType,
  shouldShowBillingUsage,
} from "selectors/LicenseSelectors";
import { withAuthentication, withEmailVerification } from "utils/RouterUtils";
import { Text } from "@appsmith/ads";
import {
  shouldShowBusinessAccountUsage,
  shouldShowEnterpriseAccountUsage,
} from "selectors/AccountSelectors";
import SelfServeUsage from "features/usage-details/selfserve-usage/SelfServeUsage";
import EnterpriseUsage from "features/usage-details/enterprise-usage/EnterpriseUsage";
import { FlowType } from "types/LicenseTypes";
import { SeatBased } from "./seatBased";
import { LICENSE } from "constants/RouteContants";

function Billing() {
  const isSelfServe = useSelector(shouldShowBusinessAccountUsage);
  const isEnterprise = useSelector(shouldShowEnterpriseAccountUsage);
  const showBillingUsage = useSelector(shouldShowBillingUsage);
  const flowType = useSelector(getFlowType);

  if (flowType === FlowType.SEAT_BASED_UPDATE && !isEnterprise) {
    return (
      <PageContainer>
        <SeatBased />
      </PageContainer>
    );
  } else if (showBillingUsage) {
    return (
      <PageContainer data-testid="t--billing-page">
        <Text className="!font-semibold !mb-6" kind="heading-l" renderAs="p">
          {createMessage(BILLING_AND_USAGE_HEADING)}
        </Text>
        {isSelfServe && <SelfServeUsage />}
        {isEnterprise && <EnterpriseUsage />}
      </PageContainer>
    );
  } else {
    return <Navigate to={LICENSE} />;
  }
}

export default withAuthentication(withEmailVerification(Billing));
