import { AxiosPromise } from "axios";
import { ApiResponse } from "types/ApiTypes";

import API from "./Api";
import { PAYMENTS_CHECKOUT, PAYMENTS_DASHBOARD } from "./constants";

class PaymentsApi {
  static async initiateCheckout(): AxiosPromise<ApiResponse<unknown>> {
    return API.post(PAYMENTS_CHECKOUT);
  }

  static async goToDashboard(): AxiosPromise<ApiResponse<string>> {
    return API.get(PAYMENTS_DASHBOARD);
  }

  static async initiateSeatBasedCheckout(
    seatCount: number,
  ): AxiosPromise<ApiResponse<unknown>> {
    return API.post(PAYMENTS_CHECKOUT, {
      seatCount,
    });
  }
}

export default PaymentsApi;
