import React from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import {
  Container,
  DetailsContainer,
  LoaderContainer,
} from "./LicenseDetails.styles";
import {
  ACCOUNT_STATUS_TYPES,
  DANGER_STATES,
} from "constants/AccountTypeConstants";
import { Icon, Text } from "@appsmith/ads";
import LicenseKey from "features/license-key/LicenseKey";
import LicenseActions from "features/license-actions/LicenseActions";
import {
  LICENSE_INSTALLATION_HEADING,
  createMessage,
} from "constants/Messages";
import { getAccountType } from "selectors/AccountSelectors";
import { loadingLicenseDetails } from "selectors/LicenseSelectors";
import Loading from "components/loading/Loading";

function LicenseDetails() {
  const [showLicenseKey, setShowLicenseKey] = useState(false);
  const accountType = useSelector(getAccountType);
  const isLicenseLoading = useSelector(loadingLicenseDetails);

  function toggleShowLicenseKey() {
    setShowLicenseKey(!showLicenseKey);
  }

  return (
    <>
      <Text className="!font-semibold" kind="heading-s" renderAs="p">
        {createMessage(LICENSE_INSTALLATION_HEADING)}
      </Text>
      <Container>
        {isLicenseLoading ? (
          <LoaderContainer>
            <Loading inline />
          </LoaderContainer>
        ) : (
          <>
            <DetailsContainer>
              <Icon
                color={
                  DANGER_STATES.includes(accountType as ACCOUNT_STATUS_TYPES)
                    ? "var(--ads-v2-color-fg-error)"
                    : "var(--ads-v2-color-fg-success)"
                }
                name="key-2-line"
                size="lg"
              />
              <LicenseKey showLicenseKey={showLicenseKey} />
            </DetailsContainer>
            <LicenseActions
              showLicenseKey={showLicenseKey}
              toggleShowLicenseKey={toggleShowLicenseKey}
            />
          </>
        )}
      </Container>
    </>
  );
}

export default LicenseDetails;
