import React from "react";
import styled from "styled-components";

interface UlListProps {
  children: React.ReactNode;
}

const StyledUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    position: relative;
    padding: var(--ads-v2-spaces-2) 0;
  }

  li:before {
    content: "•";
    position: absolute;
    left: 0;
    top: 0;
    margin-right: var(--ads-v2-spaces-2);
    transform: translateY(0.2em);
    color: var(--ads-v2-color-fg);
  }

  li > * {
    margin-left: calc(var(--ads-v2-spaces-2) + 1em);
    display: inline-block;
  }
`;

const UlList: React.FC<UlListProps> = ({ children }) => {
  return <StyledUl>{children}</StyledUl>;
};

export { UlList };
