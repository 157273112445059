import React from "react";
import { useSelector } from "react-redux";
import { Flex, Text, Tag, Icon, Tooltip } from "@appsmith/ads";

import { AddOrRemoveSeats, Card } from "components";
import {
  ALL_SEATS_ASSIGNED_TOOLTIP,
  CURRENT_CYCLE_TEXT,
  ENTERPRISE,
  PLAN_TAG,
  SELF_SERVE,
  SEATS_AVAILABLE_TEXT,
  SEATS_TEXT,
  TOTAL_CONSUMED_SEATS_TEXT,
  VALID_TILL_TEXT,
} from "constants/Messages";
import { createMessage } from "constants/Messages";
import { UsageBar } from "components/usageBar";
import { getCurrentLicenseExpiry } from "selectors/LicenseSelectors";
import { getDateString } from "utils/CommonUtils";
import { getOccupiedSeatCount } from "selectors/MembersSelectors";
import { getPurchasedSeatCount } from "selectors/MembersSelectors";
import { shouldShowEnterpriseAccountUsage } from "selectors/AccountSelectors";

const SeatsCard = () => {
  const expiry = useSelector(getCurrentLicenseExpiry);
  const purchasedSeatCount = useSelector(getPurchasedSeatCount);
  const occupiedSeatCount = useSelector(getOccupiedSeatCount);
  const isEnterprise = useSelector(shouldShowEnterpriseAccountUsage);

  const totalSeats = purchasedSeatCount || 0;
  const occupiedSeats = occupiedSeatCount || 0;
  const availableSeats = totalSeats - occupiedSeats;

  return (
    <Card flexDirection="column" gap="spaces-7">
      <Flex justifyContent="space-between" width="100%">
        <Flex flexDirection="column" gap="spaces-2">
          <Flex alignItems="center" gap="spaces-4" justifyContent="center">
            <Text kind="heading-m">{createMessage(CURRENT_CYCLE_TEXT)}</Text>
            <Tag isClosable={false} kind={isEnterprise ? "special" : "premium"}>
              {isEnterprise
                ? createMessage(PLAN_TAG, ENTERPRISE())
                : createMessage(PLAN_TAG, SELF_SERVE())}
            </Tag>
          </Flex>
          <Text kind="body-m">
            {createMessage(VALID_TILL_TEXT, getDateString(expiry))}
          </Text>
        </Flex>
        <AddOrRemoveSeats />
      </Flex>

      <Flex flexDirection="column" gap="spaces-4" width="100%">
        <Text kind="heading-s">{createMessage(TOTAL_CONSUMED_SEATS_TEXT)}</Text>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Flex alignItems="baseline" flexDirection="row" gap="spaces-2">
            <Text kind="heading-m">
              {createMessage(SEATS_TEXT, occupiedSeats)}
            </Text>
            {availableSeats === 0 && (
              <Tooltip
                content={createMessage(ALL_SEATS_ASSIGNED_TOOLTIP)}
                placement="right"
              >
                <Icon
                  className="relative top-[2px]"
                  color="var(--ads-v2-color-fg-warning)"
                  name="alert-line"
                  size="md"
                />
              </Tooltip>
            )}
          </Flex>
          <Text kind="heading-xs">
            {createMessage(SEATS_AVAILABLE_TEXT, availableSeats)}
          </Text>
        </Flex>
        <UsageBar total={totalSeats} usage={occupiedSeats} />
      </Flex>
    </Card>
  );
};

export { SeatsCard };
