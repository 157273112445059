import { Link } from "@appsmith/ads";
import styled from "styled-components";

export const UsageCard = styled.div`
  padding: 24px;
  background-color: var(--ads-v2-color-bg);
  border-radius: 10px;
  border: 1px solid var(--ads-v2-color-border);
  margin-bottom: 24px;
  margin-top: 12px;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 24px;
`;

export const DateHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledDivTag = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

export const StyledDivTagWrapper = styled.div`
  display: flex;
`;

// usage details
export const InnerBody = styled.div`
  min-height: 100px;
`;

export const Divider = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const RowItem = styled.div`
  width: 15%;
  text-align: center;
`;

export const StyledLink = styled(Link)`
  display: inline;
`;

export const HGap = styled.div`
  width: 320px;
`;

export const LegendFg1 = styled.div`
  width: 13px;
  height: 13px;
  background-color: var(--ads-v2-color-fg);
`;

export const LegendFg2 = styled.div`
  width: 13px;
  height: 13px;
  background-color: var(--ads-v2-color-bg-brand);
`;
