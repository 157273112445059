import React from "react";
import {
  createMessage,
  USAGE_ERROR_MESSAGE_BODY,
  USAGE_ERROR_MESSAGE_HEADER,
  USAGE_ERROR_MESSAGE_RETRY,
} from "constants/Messages";
import { Button, Icon, Text } from "@appsmith/ads";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { ReduxActionTypes } from "constants/ReduxActionConstants";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px 10px;
`;
const ButtonWrapper = styled.div`
  margin-top: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin-bottom: 5px;
  }
`;

const StyledIcon = styled(Icon)`
  margin-bottom: 30px;
  svg {
    transform: scale(3.5);

    path {
      fill: var(--ads-v2-color-fg-on-error);
    }
  }
`;

export function UsageError() {
  const dispatch = useDispatch();

  return (
    <Container>
      <Content>
        <StyledIcon name="cloud-off-line" />
        <Text kind="heading-xs">
          {createMessage(USAGE_ERROR_MESSAGE_HEADER)}
        </Text>
        <Text kind="body-s">{createMessage(USAGE_ERROR_MESSAGE_BODY)}</Text>
        <ButtonWrapper>
          <Button
            kind="secondary"
            onClick={() =>
              dispatch({ type: ReduxActionTypes.FETCH_USAGE_DETAILS })
            }
            type="button"
          >
            {createMessage(USAGE_ERROR_MESSAGE_RETRY)}
          </Button>
        </ButtonWrapper>
      </Content>
    </Container>
  );
}
