import { useMemo } from "react";
import { useSelector } from "react-redux";

import { ACCOUNT_STATUS_TYPES } from "constants/AccountTypeConstants";
import { getAccountType } from "selectors/AccountSelectors";

const useCanModifySeats = () => {
  const licenseType = useSelector(getAccountType);

  return useMemo(
    () => licenseType === ACCOUNT_STATUS_TYPES.PAID_ACTIVE,
    [licenseType],
  );
};

export { useCanModifySeats };
