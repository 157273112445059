import { createSelector } from "reselect";
import { LicenseStateType } from "reducers/LicenseReducer";
import { AppState } from "reducers";
import {
  ACCOUNT_STATUS_TYPES,
  PLAN_BUILDER_STATES,
  USAGE_STATES,
} from "constants/AccountTypeConstants";
import {
  LICENSE_ORIGIN,
  LICENSE_PLAN,
  PRODUCT_EDITION,
} from "constants/LicenseConstants";
import { getAccountType } from "./AccountSelectors";
import { UsageState } from "reducers/UsageReducer";
import { FlowType } from "types/LicenseTypes";

export const getLicense = (state: AppState): LicenseStateType => state.license;
export const getUsage = (state: AppState): UsageState => state.usage;

export const loadingLicenseDetails = createSelector(
  [getLicense],
  (licenseSlice) => licenseSlice.isLoading,
);

export const trialLoader = createSelector(
  [getLicense],
  (licenseSlice) => licenseSlice.startTrialLoader,
);

export const getLicenseDetails = createSelector(
  [getLicense],
  (licenseSlice) => licenseSlice.details,
);

export const selectLicenseKey = createSelector(
  [getLicense],
  (licenseSlice) => licenseSlice.details?.licenseKey,
);

export const getCurrentLicenseExpiry = createSelector(
  [getLicense],
  (licenseSlice) => licenseSlice.details?.expiry,
);

export const getLicenseOrigin = createSelector([getLicense], (licenseSlice) => {
  return licenseSlice.details?.licenseOrigin || "";
});

export const getLicensePlan = createSelector([getLicense], (licenseSlice) => {
  return licenseSlice.details?.licensePlan || LICENSE_PLAN.FREE;
});

export const getProductEdition = createSelector(
  [getLicense],
  (licenseSlice) => {
    return licenseSlice.details?.productEdition || PRODUCT_EDITION.COMMUNITY;
  },
);

export const getFlowType = createSelector([getLicense], (licenseSlice) => {
  return licenseSlice.flowType;
});

export const isLicenseOriginSelfServe = (state: AppState) => {
  const licenseDetails = state.license?.details;
  return licenseDetails?.licenseOrigin === LICENSE_ORIGIN.SELF_SERVE;
};

export const shouldShowPlans = createSelector(
  [getAccountType, isLicenseOriginSelfServe, getFlowType],
  (accountType, isSelfServe, flowType) => {
    if (flowType === FlowType.SEAT_BASED_UPDATE) {
      return false;
    }
    return (
      PLAN_BUILDER_STATES.includes(accountType as ACCOUNT_STATUS_TYPES) ||
      (accountType === ACCOUNT_STATUS_TYPES.PAID_EXPIRED && isSelfServe)
    );
  },
);

export const shouldShowBillingUsage = createSelector(
  [getLicense, getAccountType],
  (licenseSlice, accountType) => {
    if (licenseSlice.details?.licenseOrigin === LICENSE_ORIGIN.SELF_SERVE) {
      return USAGE_STATES.includes(accountType as ACCOUNT_STATUS_TYPES);
    }
    if (
      accountType === ACCOUNT_STATUS_TYPES.PAID_EXPIRED ||
      licenseSlice.flowType !== FlowType.USAGE_BASED
    ) {
      return false;
    }
    if (
      licenseSlice.details?.licenseOrigin === LICENSE_ORIGIN.SALES ||
      licenseSlice.details?.licenseOrigin === LICENSE_ORIGIN.PARTNERSHIP
    ) {
      return (
        licenseSlice.details?.stripeSubscriptionId &&
        licenseSlice.details?.productEdition !== PRODUCT_EDITION.AIR_GAP
      );
    }
  },
);

export const shouldShowMembers = createSelector([getFlowType], (flowType) => {
  return flowType === FlowType.SEAT_BASED_UPDATE;
});

export const getBillingModel = createSelector([getLicense], (licenseSlice) => {
  return licenseSlice.details?.billingModel || null;
});
