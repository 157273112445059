import { ReduxActionTypes } from "constants/ReduxActionConstants";
import {
  GetMembersCountPayload,
  Member,
  GetMembersSuccessPayload,
  InviteMembersPayload,
  UpdateMembersPayload,
} from "types/MembersTypes";
import { createAction } from "utils/ActionUtils";
import { FetchMembersPayload } from "types/MembersTypes";

export const fetchMembers = (payload: FetchMembersPayload) =>
  createAction(ReduxActionTypes.FETCH_MEMBERS, payload);

export const fetchMembersSuccess = (val: GetMembersSuccessPayload) =>
  createAction(ReduxActionTypes.FETCH_MEMBERS_SUCCESS, val);

export const fetchMembersFailed = (val: string) =>
  createAction(ReduxActionTypes.FETCH_MEMBERS_FAILED, val);

export const inviteMembers = (val: InviteMembersPayload) =>
  createAction(ReduxActionTypes.INVITE_MEMBERS, val);

export const inviteMembersSuccess = (val: Member[]) =>
  createAction(ReduxActionTypes.INVITE_MEMBERS_SUCCESS, val);

export const inviteMembersFailed = (val: string) =>
  createAction(ReduxActionTypes.INVITE_MEMBERS_FAILED, val);

export const inviteMembersStatusReset = () =>
  createAction(ReduxActionTypes.INVITE_MEMBERS_STATUS_RESET);

export const updateMembers = (val: UpdateMembersPayload) =>
  createAction(ReduxActionTypes.UPDATE_MEMBERS, val);

export const updateMembersSuccess = () =>
  createAction(ReduxActionTypes.UPDATE_MEMBERS_SUCCESS);

export const updateMembersFailed = () =>
  createAction(ReduxActionTypes.UPDATE_MEMBERS_FAILED);

export const setSeatAssignLoading = (val: boolean) =>
  createAction(ReduxActionTypes.MEMBERS_SET_ASSIGN_SEATS_LOADING, val);

export const setSeatUnassignLoading = (val: boolean) =>
  createAction(ReduxActionTypes.MEMBERS_SET_UNASSIGN_SEATS_LOADING, val);

export const fetchMembersCount = () =>
  createAction(ReduxActionTypes.FETCH_MEMBERS_COUNT);

export const fetchMembersCountSuccess = (val: GetMembersCountPayload) =>
  createAction(ReduxActionTypes.FETCH_MEMBERS_COUNT_SUCCESS, val);

export const fetchMembersCountFailed = (val: string) =>
  createAction(ReduxActionTypes.FETCH_MEMBERS_COUNT_FAILED, val);
